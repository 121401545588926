const shippingMethods = `
<div data-shipping-methods-container class="bold-ro__shipping-rates-container bold-ro__fadeIn">
{{#need_change}}
<p class='bold-ro__rate-change'>{{#translate}}add_to_existing_ship_select{{/translate}}</p>
<span class='bold-ro__shipping-note'>{{#translate}}add_to_existing_ship_note{{/translate}}</span>
{{#shipping_rates}}
    <div class='radio'>
        <label>
            <input data-source='{{rate}}' {{#checked}}checked="checked"{{/checked}} data-hash='{{hash}}' name='shipping_rate' type='radio' value='{{rate}}'>&nbsp;{{name}} - {{#format_money}}{{price}}{{/format_money}}
        </label>
     </div>
{{/shipping_rates}}    
{{/need_change}}
{{^need_change}}
    {{^error}}
        <span class='bold-ro__no-shipping-methods'>{{#translate}}no_ship_rates_found{{/translate}}</span>
    {{/error}}
    {{#error}}
        <span class='bold-ro__error-shipping-methods'>{{#translate}}add_to_existing_ship_error{{/translate}}</span>
    {{/error}}
    <input name='shipping_rate' type='hidden' value=''>
{{/need_change}}
</div>
`;

export default shippingMethods;
