import { JSHelper, ShopifyHelper } from '@bold-commerce/bold-common-js';
import { map } from 'lodash';
import queryStringHelper from 'query-string';
import recurringOrdersAPI from './RecurringOrdersAPI';
import {
    ADD_TO_CART_BUTTON,
    ATE_BUTTON_CLASS,
    CLASS_ADD_TO_CART_BUTTON,
    NAME_QUANTITY,
    OPEN_ADD_TO_EXISTING,
    OPEN_ADD_TO_EXISTING_ATTRIBUTE,
    RECURRING_TYPE_RECURRING_CART,
} from '../constants/index';

export function createStyleSheet(cssText) {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = cssText;
    document.getElementsByTagName('head')[0].appendChild(styleTag);
}

export function loadScript(url, callbackArray) {
    const script = document.createElement('script');

    if (script.readyState) { // IE
        script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                callbackArray.forEach((callback) => callback());
            }
        };
    } else { // Others
        script.onload = () => {
            callbackArray.forEach((callback) => callback());
        };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
}

/**
 * Get all the polyfills and load them into the queue
 */
export function loadPolyfills(polyfills) {
    const polyfillsToLoad = {};

    // create list of polyfillsToLoad with all callbacks
    polyfills.forEach((poly) => {
        if (typeof polyfillsToLoad[poly.name] === 'undefined') {
            polyfillsToLoad[poly.name] = {
                polyfills,
                callbacks: [poly.callback],
            };
        } else { // already in the list of polyfills to load -- just add callback
            polyfillsToLoad[poly.name].callbacks.push(poly.callback);
        }
    });

    // load polyfills
    if (polyfillsToLoad) {
        Object.keys(polyfillsToLoad).forEach((name) => {
            const { polyfill } = polyfillsToLoad[name];
            const { callbacks } = polyfillsToLoad[name];

            if (polyfill.isRequired() && !polyfill.alreadyLoaded()) {
                if (typeof polyfill.css === 'string') {
                    JSHelper.loadCSS(polyfill.css);
                }
                loadScript(polyfill.url, callbacks);
            }
        });
    }
}

export function loadTranslations() {
    return recurringOrdersAPI.retrieve({ endpoint: 'translations' }).then((respJSON) => {
        if (respJSON.error) {
            throw respJSON.error;
        }

        window.BOLD.recurring_orders.language = respJSON;
    });
}

export function getCheckoutProcessingStatus() {
    return recurringOrdersAPI.retrieve({
        endpoint: 'checkout_processing',
    }).then((respJSON) => {
        if (respJSON.error) {
            throw respJSON.error;
        }

        return respJSON;
    });
}

let domLoadedPromiseResolve;
const domLoadedPromise = new Promise((resolve) => { domLoadedPromiseResolve = resolve; });
document.addEventListener('DOMContentLoaded', () => {
    domLoadedPromiseResolve();
});
export function getDOMLoadedPromise() {
    return domLoadedPromise;
}

export function removeProductDescriptionsFromCart(cartJSON) {
    for (let i = 0; i < cartJSON.items.length; i += 1) {
        cartJSON.items[i].product_description = '';
    }
}

export function getGATracker() {
    return new Promise((resolve) => {
        window.ga((tracker) => resolve(tracker));

        setTimeout(() => {
            if (typeof window.ga.getAll === 'function') {
                const trackers = window.ga.getAll();

                if (trackers.length && trackers[0]) {
                    resolve(trackers[0]);
                }
            }
            resolve(false);
        }, 50);
    });
}

export function applyLinkerParams(filteredSearch) {
    return new Promise((resolve) => {
        if (typeof window.ga !== 'function') {
            resolve(filteredSearch);
            return;
        }
        getGATracker()
            .then((tracker) => {
                if (!tracker || typeof tracker.get !== 'function') {
                    resolve(filteredSearch);
                    return;
                }

                const linkerParam = tracker.get('linkerParam');

                if (linkerParam && linkerParam.length > 0) {
                    const [, gaValue] = linkerParam.split('=');

                    if (gaValue) {
                        filteredSearch._ga = gaValue;
                    }
                }

                resolve(filteredSearch);
            });
    });
}

/**
 * returns a promise that evaluates to a string in the format '?key1=value1&key2=value2' and so on. If there are no params, an empty string is returned.
 */
export function googleAnalyticsGetParamString(otherParams) {
    return new Promise((resolve) => {
        const allowedParams = {
            utm_source: true,
            utm_medium: true,
            utm_term: true,
            utm_content: true,
            utm_campaign: true,
            _ga: true,
            _gl: true,
            _conv_s: true,
            _conv_v: true,
        };

        const parsedSearch = queryStringHelper.parse(window.location.search);
        const filteredSearch = {};

        Object.keys(parsedSearch).forEach((k) => {
            if (allowedParams[k]) {
                filteredSearch[k] = parsedSearch[k];
            }
        });

        applyLinkerParams(filteredSearch)
            .then((newFilteredSearch) => {
                Object.assign(newFilteredSearch, otherParams);

                let getParamString = queryStringHelper.stringify(newFilteredSearch);

                if (getParamString.length > 0) {
                    getParamString = `?${getParamString}`;
                }
                resolve(getParamString);
            });
    });
}

const boundElements = [];
const boundEvents = {};
export function delegateEvent(eventName, passedSelector, passedCallback, element = document.body) {
    let elementIndex = boundElements.indexOf(element);
    if (elementIndex === -1) {
        elementIndex = boundElements.length;
        boundElements.push(element);
    }

    let elementEvents = boundEvents[elementIndex];
    if (!elementEvents) {
        elementEvents = {};
        boundEvents[elementIndex] = elementEvents;
    }

    let eventSelectors = elementEvents[eventName];
    if (!eventSelectors) {
        eventSelectors = {};
        elementEvents[eventName] = eventSelectors;
        element.addEventListener(eventName, (e) => {
            Object.keys(eventSelectors).forEach((selector) => {
                if (e.target.matches(selector)) {
                    eventSelectors[selector].forEach((callback) => {
                        callback(e);
                    });
                }
            });
        });
    }

    let selectorCallbacks = eventSelectors[passedSelector];
    if (!selectorCallbacks) {
        selectorCallbacks = [];
        eventSelectors[passedSelector] = selectorCallbacks;
    }
    selectorCallbacks.push(passedCallback);
}

export function unbindDelegateEvent(eventName, passedSelector, passedCallback, element = document.body) {
    const elementIndex = boundElements.indexOf(element);
    if (elementIndex !== -1) {
        const elementEvents = boundEvents[elementIndex];
        if (elementEvents) {
            const eventSelectors = elementEvents[eventName];
            if (eventSelectors) {
                const selectorCallbacks = eventSelectors[passedSelector];
                if (selectorCallbacks) {
                    let callbackIndex = selectorCallbacks.indexOf(passedCallback);
                    while (callbackIndex !== -1) {
                        selectorCallbacks.splice(callbackIndex, 1);
                        callbackIndex = selectorCallbacks.indexOf(passedCallback);
                    }
                }
            }
        }
    }
}

export function commonMergeFieldFunctions(fields) {
    const { BOLD: { recurring_orders: { language } } } = window;
    fields.translate = () => (text, render) => {
        const result = language.translations[render(text)];
        return render(result);
    };
    fields.translate_days = () => (text, render) => {
        const result = language.days_of_week[render(text)];
        return render(result);
    };
    fields.format_money = () => (text, render) => {
        let value = render(text);
        value = parseFloat(value);
        return ShopifyHelper.displayMoney(value, fields.money_format);
    };
    return fields;
}

export function createElementWithAttr(tagName, attr) {
    const el = document.createElement(tagName);
    Object.keys(attr).forEach((key) => { el.setAttribute(key, attr[key]); });
    return el;
}

/**
 * First look for the shop metafield being assigned to the BOLD.recurring_orders.modes object.
 *  If it doesn't exist the RecurringOrdersCart value is used to determine whether or not the shop is
 *  in recurring cart mode.
 * @returns {boolean}
 */
export function isRecurringCartMode() {
    const { BOLD: { recurring_orders: { modes } } } = window;
    const { BOLD: { recurring_orders: { app: { cartWidget } } } } = window;
    return (modes) ? (modes.current_mode === RECURRING_TYPE_RECURRING_CART) : cartWidget.isRecurringCart;
}

export function addExistingOrdersButton(form, productId, groupData = null) {
    const { BOLD: { recurring_orders: { settings, language } } } = window;
    const { BOLD: { customer } } = window;
    const addToOrderExists = form.querySelector(OPEN_ADD_TO_EXISTING);
    if (customer && customer.id
        && (isRecurringCartMode()
        || (groupData !== null
        && !groupData.conversion
        && !groupData.subscription_box
        && !groupData.secondary_discount
        && !groupData.is_prepaid_only))
    ) {
        const buttons = form.querySelectorAll(ADD_TO_CART_BUTTON);
        map(buttons, (button) => {
            const addToOrderButtonExists = form.querySelector(OPEN_ADD_TO_EXISTING);
            if (!addToOrderButtonExists) {
                const addToOrderButton = document.createElement('a');
                let btnClasses = `${settings.loadAddToExistingButtonClasses} ${ATE_BUTTON_CLASS}${productId} `;
                if (settings.useAddToCartClasses) {
                    btnClasses = btnClasses.concat(button.className);
                }
                addToOrderButton.className = btnClasses;
                addToOrderButton.classList.remove(CLASS_ADD_TO_CART_BUTTON);
                addToOrderButton.appendChild(document.createTextNode(language.translations.add_to_existing_orders));
                addToOrderButton.setAttribute('style', 'display: none;');
                addToOrderButton.setAttribute(OPEN_ADD_TO_EXISTING_ATTRIBUTE, '');
                button.parentNode.insertBefore(addToOrderButton, button.nextSibling);
            }
        });
        // Clone all Add to Existing Buttons if options exists so that the required options are respected
        const addToExistingButton = form.querySelector(`.${ATE_BUTTON_CLASS}${productId}`);
        if (addToExistingButton) {
            window.BOLD.common.eventEmitter.emit('BOLD_COMMON_clone_addtocart_button', addToExistingButton);
        }
    } else if (addToOrderExists) {
        addToOrderExists.remove();
    }
}

export function showAddToExistingButton(form) {
    map(form.querySelectorAll(OPEN_ADD_TO_EXISTING), (button) => {
        button.removeAttribute('style');
    });
}

export function hideAddToExistingButton(form) {
    map(form.querySelectorAll(OPEN_ADD_TO_EXISTING), (button) => {
        button.setAttribute('style', 'display: none;');
    });
}

export function getFormQuantity(form) {
    let quantity = 1;
    const quantityElem = form.querySelector(NAME_QUANTITY);
    if (quantityElem) {
        quantity = quantityElem.value;
    }
    return quantity;
}

export function shippingPriceToFloat(rates) {
    Object.values(rates).forEach((rate) => {
        rate.price = parseFloat((rate.price * 100).toFixed(2));
    });
    return rates;
}

export function getFormArray(form) {
    let field = [];
    let l = [];
    const s = [];

    if (typeof form === 'object' && form.nodeName === 'FORM') {
        const len = form.elements.length;
        for (let i = 0; i < len; i += 1) {
            field = form.elements[i];
            if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
                if (field.type === 'select-multiple') {
                    l = form.elements[i].options.length;
                    let j = 0;
                    for (j = 0; j < l; j += 1) {
                        if (field.options[j].selected) {
                            s[s.length] = { name: field.name, value: field.options[j].value };
                        }
                    }
                } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                    s[s.length] = { name: field.name, value: field.value };
                }
            }
        }
    }
    return s;
}

export function getProp(formElements, propertyName) {
    for (let i = 0; i < formElements.length; i += 1) {
        if (formElements[i].name === propertyName) {
            return formElements[i].value;
        }
    }
    return null;
}

export function getPropsFromForm(form) {
    const formElements = getFormArray(form);
    const props = {};
    const NAME = 'name';
    const VALUE = 'value';

    formElements.forEach((p) => {
        if (p[NAME].includes('properties[')) {
            const name = p[NAME].replace(/^properties\[/, '').slice(0, -1);
            props[name] = p[VALUE];
        }
    });

    return props;
}

export function isLocalStorageNameSupported() {
    const testKey = 'ro_test_key';
    const storage = window.sessionStorage;
    try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return true;
    } catch (error) {
        console.log('localStorage not working');
        return false;
    }
}

export function toggleAdditionalCheckout(resp) {
    const { BOLD: { recurring_orders: { settings: { loadAdditionalCheckoutSelector } } } } = window;
    const additionalCheckoutSelector = (loadAdditionalCheckoutSelector !== '') ? document.querySelectorAll(`${loadAdditionalCheckoutSelector}`) : false;
    let hideBtn = false;
    if (resp.event === 'recurring_cart_changed') {
        const { data: { isRecurringCart } } = resp;
        hideBtn = additionalCheckoutSelector && isRecurringCart === 1;
    } else {
        const mixedCart = window.BOLD.common.cartDoctor ? window.BOLD.common.cartDoctor.cart.is_recurring : false;
        hideBtn = additionalCheckoutSelector && mixedCart;
    }
    map(additionalCheckoutSelector, (element) => {
        element.setAttribute('style', hideBtn ? 'display:none' : '');
    });
}

export function toggleBuyNowButton(resp) {
    const { event } = resp;
    const { BOLD: { recurring_orders: { settings: { loadAdditionalBuyButtonSelector } } } } = window;
    const additionalBuyButtonSelector = (loadAdditionalBuyButtonSelector !== '') ? document.querySelectorAll(`${loadAdditionalBuyButtonSelector}`) : false;
    const hideBtn = additionalBuyButtonSelector && (event === 'mixed_product_recurring' || event === 'single_product_recurring');
    map(additionalBuyButtonSelector, (element) => {
        element.setAttribute('style', hideBtn ? 'display:none' : '');
    });
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return '';
}

export function isValidGroup(groupId) {
    let result = false;
    if (groupId !== undefined && groupId !== '' && groupId !== 'null') {
        result = true;
    }
    return result;
}

/**
 * Calculates the discount on each item and then rounds that number to 2 decimal places allowing the
 *   total to be the same as the checkout.
 * @param discount
 * @param cartItems
 * @returns {number}
 */
export function calculateDiscountedCartPrice(discount, cartItems) {
    const multiplier = (1 - (discount / 100));
    let total = 0.00;
    for (let i = 0; i < cartItems.length; i += 1) {
        total += (Math.round(cartItems[i].price * multiplier)) * cartItems[i].quantity;
    }
    return total;
}

/**
 * Concatenates the variant option titles together with a slash separating each option title
 * @param variantTitles
 * @returns {string}
 */
export function concatConvertibleOptionTitles(variantTitles) {
    return Object.keys(variantTitles).filter((k) => variantTitles[k] !== null).map((k) => variantTitles[k]).join(' / ');
}

/**
 * Takes in the convertible product handle and group id and returns the convertible product details
 * @param handle
 * @param groupId
 * @returns {{}}
 */
export function getConvertibleProduct(handle, groupId) {
    let convertibleProduct = {};
    if (window.BOLD.recurring_orders.convertible_products !== undefined
        && window.BOLD.recurring_orders.convertible_products[handle] !== undefined) {
        convertibleProduct = window.BOLD.recurring_orders.convertible_products[handle];
    } else if (window.BOLD.recurring_orders.cached_group !== undefined
        && window.BOLD.recurring_orders.cached_group[groupId] !== undefined
        && window.BOLD.recurring_orders.cached_group[groupId].conversion !== undefined) {
        const convertibleData = window.BOLD.recurring_orders.cached_group[groupId].conversion;
        convertibleProduct = {
            title: convertibleData.product_details.title,
            price: convertibleData.price,
            variants: [{
                id: parseInt(convertibleData.variant_id, 10),
                title: `${concatConvertibleOptionTitles(convertibleData.product_details.variant_title)}`,
                name: `${convertibleData.product_details.title} ${concatConvertibleOptionTitles(convertibleData.product_details.variant_title)}`,
                price: convertibleData.price,
            }],
        };
        window.BOLD.recurring_orders.convertible_products = window.BOLD.recurring_orders.convertible_products || {};
        window.BOLD.recurring_orders.convertible_products[handle] = convertibleProduct;
    }
    return convertibleProduct;
}

export function generateConvertibleLineItemProperty(groupId, variantId, convertibleDiscount, productHandle, htmlFriendly = false) {
    const convertibleProduct = getConvertibleProduct(productHandle, groupId);
    const convertibleVariant = convertibleProduct.variants.find(
        (v) => v.id === parseInt(variantId, 10),
    );
    let convertibleProductName = convertibleProduct.title;
    let convertibleProductPrice = convertibleProduct.price;

    if (convertibleVariant) {
        convertibleProductPrice = convertibleVariant.price;
        if (convertibleVariant.title) {
            convertibleProductName += ` - ${convertibleVariant.title}`;
        }
    }

    const { translations } = window.BOLD.recurring_orders.language;
    convertibleProductPrice *= (100 - convertibleDiscount) * 0.01;

    return translations.convertible_name_line_item
        .replace(
            '[convertible_product_name]',
            htmlFriendly ? `<span class="ro-convertible-name">${convertibleProductName}</span>` : convertibleProductName,
        )
        .replace(
            '[convertible_item_price]',
            htmlFriendly ? `<span class="ro-convertible-price">${ShopifyHelper.displayMoney(convertibleProductPrice)}</span>` : ShopifyHelper.displayMoney(convertibleProductPrice),
        )
        .replace(
            '[convertible_item_discount_percentage]',
            htmlFriendly ? `<span class="ro-convertible-percentage">${convertibleDiscount}</span>` : convertibleDiscount,
        );
}

export function calculateDiscountPercentPrice(discountPercent, value) {
    const discountPriceMultiplier = 1 - (parseFloat(discountPercent) / 100);
    return Math.round(value * discountPriceMultiplier, 2);
}

export function calculateDiscountPercentAmount(discountPercent, value) {
    return Math.round(value * (parseFloat(discountPercent) / 100), 2);
}
