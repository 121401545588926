import {
    CONVERTIBLE_DISCOUNT_PERCENT_KEY,
    CONVERTIBLE_DISCOUNT_PRODUCT_HANDLE_KEY,
    CONVERTIBLE_DISCOUNT_VARIANT_ID_KEY,
    CONVERTIBLE_PRODUCT_DETAILS_KEY,
    CONVERTIBLE_PRODUCT_LINE_ITEM_PROPERTY_KEY,
} from '../constants/properties';

const convertibleTable = `
<div class="bold-ro__sub-image">
    <div class="bold-ro__table">
        <div class="bold-ro__table-row">
            <div class="bold-ro__table-col-3">
                <a href="{{conversion.product_details.product_url}}"> <img src="{{conversion.product_details.image_url}}" alt="{{conversion.product_details.title}}"></a>
            </div>
            <div class="bold-ro__table-col-6">
                <div class="bold-ro__table">
                    <div class="bold-ro__table-row">
                        <div class="bold-ro__table-col"><span class="bold-ro__sub-discount-price">{{#format_money}}{{conversion.unformatted_discount_price}}{{/format_money}}</span></div>
                        <div class="bold-ro__table-col-left"><span class="bold-ro__sub-title">{{#translate}}convertible_prefix_title{{/translate}} {{conversion.product_details.title}}</span></div>
                    </div>
                    <div class="bold-ro__table-row bold-ro__bottom-info-row">
                        <div class="bold-ro__table-col"><span class="bold-ro__sub-regular-price">{{#hide_no_discount}}{{#format_money}}{{conversion.price}}{{/format_money}}{{/hide_no_discount}}</span></div>
                        <div class="bold-ro__table-col-left">
                        <span class="bold-ro__sub-variant-title">
                        {{#conversion.product_details.variant_title.option1}}{{conversion.product_details.variant_title.option1}}{{/conversion.product_details.variant_title.option1}}
                        {{#conversion.product_details.variant_title.option2}} / {{conversion.product_details.variant_title.option2}}{{/conversion.product_details.variant_title.option2}}
                        {{#conversion.product_details.variant_title.option3}} / {{conversion.product_details.variant_title.option3}}{{/conversion.product_details.variant_title.option3}}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<input type="hidden" class="bold-ro__${CONVERTIBLE_DISCOUNT_PERCENT_KEY}" value="{{conversion.discount}}"/>
<input type="hidden" class="bold-ro__${CONVERTIBLE_DISCOUNT_PRODUCT_HANDLE_KEY}" value="{{conversion.product_details.handle}}"/>
<input type="hidden" class="bold-ro__${CONVERTIBLE_DISCOUNT_VARIANT_ID_KEY}" value="{{conversion.variant_id}}"/>
<input type="hidden" class="bold-ro__${CONVERTIBLE_PRODUCT_DETAILS_KEY}" value="{{conversion.stringified_conversion }}"/>
<input type="hidden" class="bold-ro__${CONVERTIBLE_PRODUCT_LINE_ITEM_PROPERTY_KEY}" value="{{conversion.line_item_property }}"/>

`;

export default convertibleTable;
