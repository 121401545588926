export const RO_CLASS_IDENTIFIER = 'form:not([class*=no_ro_widget]) .ro_widget';
export const RO_WIDGET_CLASS_IDENTIFIER = '.ro_widget';
export const RO_NO_WIDGET_CLASS_NAME = 'no_ro_widget';
export const VARIANT_DOM_SELECTOR = 'form:not([class*=no_ro_widget]) [name="id"]';
export const VARIANT_DOM_SELECTOR_ATTR = '[name="id"]';
export const SUBSCRIPTION_STANDARD_NUM = '1';
export const SUBSCRIPTION_CONVERTIBLE_NUM = '2';
export const SUBSCRIPTION_BOX_NUM = '3';
export const TEMPLATE_SUBSCRIPTION_STANDARD = 'standard';
export const TEMPLATE_SUBSCRIPTION_CONVERTIBLE = 'convertible';
export const TEMPLATE_SUBSCRIPTION_BOX = 'subscriptionBox';
export const TEMPLATE_SHIPPING_METHODS = 'shippingMethods';
export const CART_DIV_CLASS = 'product_rp_cart_div';
export const RECURRING_TYPE_RECURRING_CART = 0;
export const RECURRING_TYPE_SINGLE_PRODUCT = 1;
export const RECURRING_TYPE_MULTI_PRODUCT = 2;
export const TEMPLATE_ADD_TO_EXISTING = 'addToExisting';
export const TEMPLATE_WIDGET_LOADING = 'widgetLoading';
export const CUSTOMER = [
    'shopify_customer_id',
    'email',
    'address1',
    'address2',
    'city',
    'company',
    'country',
    'first_name',
    'last_name',
    'phone',
    'province',
    'zip'];
export const USER_ACTIVITY_LOG_ADD_PRODUCT = 13;
export const ONE_TIME_PRODUCT = 0;
export const SINGLE_PRODUCT_RECURRING = 1;
export const MIXED_PRODUCT_RECURRING = 2;
export const BOX_PRODUCT_RECURRING = 3;
export const VARIANT_SELECTOR_TIMEOUT = 50;

/**
 * Query Selectors for Add to Existing Functionality
 */
export const OPEN_ADD_TO_EXISTING = '[data-open-add-to-existing]';
export const OPEN_ADD_TO_EXISTING_ATTRIBUTE = 'data-open-add-to-existing';
export const ADD_TO_CART_BUTTON = 'button[type="submit"]:not(.bold_hidden),.addtocart:not(.bold_hidden)';
export const CLASS_ADD_TO_CART_BUTTON = 'addtocart';
export const CLASS_ATE_BUTTON = '.bold-ro__add-to-order-';
export const CLASS_SELECTOR_BOLD_ACTIVE = '.bold-ro__bold-active';
export const CLASS_SELECTOR_RECURRING_LABEL = '.bold-ro__recurring-lbl';
export const CLASS_SELECTOR_PREPAID_CHECKBOX_LABEL = '.bold-ro__prepaid-checkbox-lbl';
export const CLASS_SELECTOR_CUSTOM_BUTTON = '.bold-ro__custombutton-';
export const CLASS_SELECTOR_RECURRING_RADIO_BUTTON = '[data-bold-recurring]';
export const CLASS_NAME_CUSTOM_BUTTON = 'bold-ro__custombutton-';
export const CLASS_NAME_BOLD_ACTIVE = 'bold-ro__bold-active';
export const CLASS_SELECTOR_FREQUENCY_NUM = '.bold-ro__frequency-num';
export const CLASS_SELECTOR_FREQUENCY_TYPE = '.bold-ro__frequency-type';
export const CLASS_SELECTOR_FREQUENCY_TYPE_TEXT = '.bold-ro__frequency-type-text';
export const CLASS_SELECTOR_PREPAID_CHECKBOX = '.bold-ro__prepaid-checkbox';
export const CLASS_SELECTOR_SUBSCRIPTION_LENGTH = '.bold-ro__subscription-length';
export const CLASS_SELECTOR_LIMITED_LENGTH_SELECT = '.bold-ro__limited-length-select';
export const CLASS_SELECTOR_PREPAID_PERCENT = '.bold-ro__prepaid-percent';
export const CLASS_SELECTOR_PREPAID_DISCOUNT_AMOUNT = '.bold-ro__prepaid-discount-amount';
export const CLASS_SELECTOR_PREPAID_DISCOUNT_PRICE = '.bold-ro__prepaid-discount-price';
export const CLASS_SELECTOR_PREPAID_TOTAL = '.bold-ro__prepaid-total';
export const CLASS_SELECTOR_PREPAID_DISCOUNT_TEXT = '.bold-ro__prepaid-discount-text';
export const CLASS_SELECTOR_PREPAID_LENGTH_SELECT = '.bold-ro__prepaid-length-select';
export const CLASS_SELECTOR_PREPAID_LENGTH = '.bold-ro__prepaid-length';
export const CLASS_SELECTOR_PREPAID_TOTAL_RECURRENCES = '.bold-ro__prepaid-total-recurrences';
export const CLASS_SELECTOR_LIMITED_LENGTH_TOTAL_RECURRENCES = '.bold-ro__limited-length-select';
export const CLASS_SELECTOR_PREPAID_TOTAL_CONTAINER = '.bold-ro__prepaid-total-container';
export const CLASS_SELECTOR_PREPAID_START_DATE_OPTION_CONTAINER = '.bold-ro__prepaid-start-date-option-container';
export const CLASS_SELECTOR_PREPAID_START_DATE = '.bold-ro__prepaid-start-date-container';
export const CLASS_SELECTOR_PREPAID_GIFT_CONTAINER = '.bold-ro__prepaid-gift-container';
export const ATE_BUTTON_CLASS = 'bold-ro__add-to-order-';
export const NAME_QUANTITY = '[name=quantity]';
export const NAME_RECURRING_RADIO_SELECTION = '[name="bold-ro__selector_radio_button"]';
export const NAME_IS_PREPAID = '[name=is_prepaid]';
export const NAME_IS_PREPAID_START_DATE = '[name=prepaid_start_date_checkbox]';
export const NAME_IS_PREPAID_LENGTH_ID = '[name=prepaid_length_id]';
export const NAME_IS_GIFT = '[name=is_gift]';
export const NAME_PREPAID_START_DATE = '[name=_prepaid_start_date]';
export const NAME_SHIPPING_RATE = '[name=shipping_rate]';
export const FORMDATA_KEY_PRODUCT_ID = 'product_id';
export const FORMDATA_KEY_VARIANT_ID = 'variant_id';
export const FORMDATA_KEY_CSRF_TOKEN = 'csrf_bold_token';
export const FORMDATA_KEY_ORDER_ID = 'order_id';
export const FORMDATA_KEY_PRODUCT = 'product';
export const FORMDATA_KEY_TOKEN = 'bold_token';
export const FORMDATA_KEY_SIGNATURE = 'bold_signature';
export const URL_SAVE_PRODUCT_INTO_ORDER = '/tools/checkout/front_end/save_product_into_order';
export const URL_CHECK_SHIPPING_RATES_PROXY = '/tools/checkout/front_end/check_shipping_rates_proxy';
export const DATA_ATE_MODAL = '[data-add-to-existing-modal]';
export const DATA_ATE_FORM = '[data-bold-form-add-to-order]';
export const DATA_ATE_BUTTONS = '[data-bold-ate-add-buttons]';
export const DATA_ATE_COMPLETE = '[data-bold-ate-complete]';
export const ID_ATE_MODAL_CONTENT = '#bold-ro__add-to-existing-modal';
export const DATA_ORDERS_SELECT = '[data-existing-orders]';
export const DATA_ATE_ADD_BUTTON = '[data-add-to-existing]';
export const DATA_LOADING = '[data-bold-loading]';
export const DATA_SHIPPING_LOADING = '[data-shipping-loading]';
export const DATA_ATE_SUCCESS = '[data-bold-add-success]';
export const DATA_ATE_ERROR = '[data-bold-add-error]';
export const DATA_ATE_SHIPPING_CONTAINER = '[data-shipping-methods-container]';
export const DATA_WIDGET_LOADING = '[data-widget-loading]';
export const DATA_PREPAID_ALWAYS_EXPIRED = '[data-is-prepaid-always-expired]';
export const NAME_PREPAID_LENGTH_ID = '[name="prepaid_length_id"]';
export const NAME_TOTAL_RECURRENCES = '[name="total_recurrences"]';
export const HTML_TOOLTIP_TEMPLATE = '<div class="bold-ro__tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
