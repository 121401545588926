/**
 * PROPERTY KEYS
 */
export const FREQUENCY_NUM_KEY = 'frequency-num';
export const FREQUENCY_TYPE_KEY = 'frequency-type';
export const FREQUENCY_TYPE_TEXT_KEY = 'frequency-type-text';
export const GROUP_ID_KEY = 'group-id';
export const DISCOUNTED_PRICE_KEY = 'discounted-price';
export const DISCOUNTED_PERCENTAGE_KEY = 'discount-percentage';
export const UNFORMATTED_PRICE_KEY = 'unformatted-price';
export const SECONDARY_DISCOUNT_NUM_KEY = 'secondary-discount';
export const SECONDARY_DISCOUNT_REQUIED_ORDERS_KEY = 'secondary-discount-required-orders';
export const CONVERTIBLE_DISCOUNT_PERCENT_KEY = 'convertible-discount-percent';
export const CONVERTIBLE_DISCOUNT_PRODUCT_HANDLE_KEY = 'convertible-product-handle';
export const CONVERTIBLE_DISCOUNT_VARIANT_ID_KEY = 'convertible-variant-id';
export const CONVERTIBLE_PRODUCT_DETAILS_KEY = 'convertible-products';
export const CONVERTIBLE_PRODUCT_LINE_ITEM_PROPERTY_KEY = 'convertible-product-details';
export const LIMITED_LENGTH_TOTAL_RECURRENCES_KEY = 'limited-length-select';

/**
 * PROPERTY VALUES
 */
export const FREQUENCY_NUM_VALUE = 'frequency_num';
export const FREQUENCY_TYPE_VALUE = 'frequency_type';
export const FREQUENCY_TYPE_TEXT_VALUE = 'frequency_type_text';
export const GROUP_ID_VALUE = 'group_id';
export const DISCOUNTED_PRICE_VALUE = 'discounted_price';
export const DISCOUNTED_PERCENTAGE_VALUE = '_ro_discount_percentage';
export const UNFORMATTED_PRICE_VALUE = '_ro_unformatted_price';
export const SECONDARY_DISCOUNT_NUM_VALUE = '_secondary_discount_percent';
export const SECONDARY_DISCOUNT_REQUIED_ORDERS_VALUE = '_secondary_discount_required_orders';
export const CONVERTIBLE_DISCOUNT_PERCENT_VALUE = '_convertible_discount_percent';
export const CONVERTIBLE_DISCOUNT_PRODUCT_HANDLE_VALUE = '_convertible_product_handle';
export const CONVERTIBLE_DISCOUNT_VARIANT_ID_VALUE = '_convertible_variant_id';
export const CONVERTIBLE_PRODUCT_DETAILS_VALUE = 'convertible_products';
export const IS_PREPAID_VALUE = 'is_prepaid';
export const IS_GIFT_VALUE = 'is_gift';
export const PREPAID_START_DATE = '_prepaid_start_date';
export const IS_RO_SINGLE_PRODUCT_RECURRING_ITEM_VALUE = '_ro_single_product_recurring_item';
export const PREPAID_LENGTH_ID_VALUE = 'prepaid_length_id';
export const TOTAL_RECURRENCES_VALUE = 'total_recurrences';
export const RO_BILLING_PLAN_VALUE = '_ro_billing_plan';
export const CONVERTIBLE_PRODUCT_LINE_ITEM_PROPERTY_VALUE = '_convertible_product_details';
export const LIMITED_LENGTH_TOTAL_RECURRENCES_VALUE = 'total_recurrences';
