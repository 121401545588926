import Mustache from 'mustache';
import { ShopifyHelper } from '@bold-commerce/bold-common-js';
import Tooltip from 'tooltip.js';
import {
    loadTranslations,
    getDOMLoadedPromise,
    delegateEvent,
    unbindDelegateEvent,
    createElementWithAttr,
    googleAnalyticsGetParamString,
    getCookie,
    calculateDiscountedCartPrice,
    removeProductDescriptionsFromCart,
} from './HelperFunctions';
import {
    isCashierAlwaysOn,
    isCashierEnabled,
    changeCashierFeatureToken,
} from '../helpers/cashierHelpers';
import * as constants from '../constants';

export default class RecurringOrdersCart {
    constructor(app) {
        this.app = app;
        this.checkoutEnabled = true;
        this.pickupEnabled = false;
        this.cart = window.BOLD.common.Shopify.cart;
        this.cartJSONUrl = '/cart.json';
        this.confirmedNoRecurringProducts = false;
        this.formParams = {};
    }

    init() {
        Promise.all([
            loadTranslations(),
            getDOMLoadedPromise(),
            this.loadCartSettings(),
        ]).then(() => {
            this.isRecurringCart = this.cartSettings.recurring_type === constants.RECURRING_TYPE_RECURRING_CART;
            this.isMultiProduct = this.cartSettings.recurring_type === constants.RECURRING_TYPE_MULTI_PRODUCT;
            this.isCashierLinked = this.cartSettings.is_cashier_linked;
            // RO Checkout through a Shopify proxy page
            this.isCheckoutProxyPage = this.cartSettings.enable_checkout_proxy_page || false;
            this.applicationUUID = window.BOLD.recurring_orders
            && window.BOLD.recurring_orders.application_uuid
                ? window.BOLD.recurring_orders.application_uuid : undefined;

            return this.setRecurringProductsInCart();
        }).then(() => {
            if ((this.isRecurringCart || this.isMultiProduct)
                && this.cartSettings.enable_widget
            ) {
                googleAnalyticsGetParamString({ shop_url: ShopifyHelper.getShopUrl() })
                    .then((queryString) => {
                        const cartToken = this.cart.token ? `/${this.cart.token}` : '';
                        let checkoutUrl;

                        if (this.isCheckoutProxyPage) {
                            checkoutUrl = '/tools/checkout/subscriptions';
                        } else {
                            checkoutUrl = `${this.cartSettings.s_shop_url.replace('ro.boldapps.net', 'recurringcheckout.com')}checkout`;
                        }

                        this.multiProductAction = `${checkoutUrl}/recurring${cartToken}${queryString || ''}`;
                        this.recurringCartAction = `${checkoutUrl}/recurring_full_cart${queryString || ''}`;
                        this.formParams = {
                            [this.cartSettings.csrf_token_name]: this.cartSettings.csrf_token,
                            frequency_type: this.cartSettings.intervals.length > 0 ? this.cartSettings.intervals[0].default_id : null,
                            billing_plan: this.cartSettings.billing_plans.length > 0 ? this.cartSettings.billing_plans[0].id : null,
                            frequency_num: 1,
                            frequency_type_text: this.cartSettings.intervals.length > 0 ? `${this.cartSettings.intervals[0].interval_text}(s)` : '',
                        };
                        this.loadCustomerData();

                        this.render();
                        this.bindEvents();
                    });
            }
        }).catch((e) => console.error('RecurringOrdersCart:', e));
    }

    loadCustomerData() {
        if (window.BOLD && window.BOLD.customer) {
            constants.CUSTOMER.forEach((key) => {
                this.formParams[key] = window.BOLD.customer[key] || '';
                if (key === 'shopify_customer_id') {
                    this.formParams.shopify_customer_id = window.BOLD.customer.shopify_customer_id || window.BOLD.customer.id || '';
                }
            });
        }
    }

    loadCartSettings() {
        return this.app.api.retrieve({ endpoint: 'recurring_cart_settings' }).then((respJSON) => {
            if (respJSON.error) {
                throw respJSON.error;
            }
            this.cartSettings = respJSON;
        });
    }

    render() {
        const cartForm = document.querySelector('form[action="/cart"]');
        if (cartForm) {
            const formCartDiv = cartForm.querySelector(`.${constants.CART_DIV_CLASS}`);
            if (!formCartDiv) {
                const shippingDivs = cartForm.querySelectorAll('[class*="shipping"], [class*="taxes"]');
                const lastElementInForm = cartForm.querySelector('form > :last-child');
                const newFormCartDiv = createElementWithAttr('div', { class: constants.CART_DIV_CLASS });
                if (shippingDivs.length > 0) {
                    shippingDivs.forEach((shippingDiv) => {
                        const cd = createElementWithAttr('div', { class: constants.CART_DIV_CLASS });
                        shippingDiv.parentNode.insertBefore(cd, shippingDiv.nextSibling);
                    });
                } else if (lastElementInForm) {
                    const updateButton = lastElementInForm.querySelector('[name="update"]');
                    if (updateButton) {
                        updateButton.parentNode.insertBefore(newFormCartDiv, updateButton);
                    } else {
                        lastElementInForm.parentNode.insertBefore(newFormCartDiv, lastElementInForm);
                    }
                } else {
                    cartForm.appendChild(newFormCartDiv);
                }
            }
        }

        const rpCartDivs = document.querySelectorAll(`.${constants.CART_DIV_CLASS}`);

        if (rpCartDivs.length > 0 && this.isRecurringCart) {
            const renderedHTML = Mustache.render(
                window.BOLD.recurring_orders.templates.recurringCart,
                this.buildTemplateObject(),
                window.BOLD.recurring_orders.templates,
            );
            rpCartDivs.forEach((cartDiv) => {
                cartDiv.innerHTML = renderedHTML;

                if (this.cartSettings.details_tooltip) {
                    const toolTip = cartDiv.querySelector('.bold-ro__detail-tooltip');

                    if (toolTip) {
                        const toolTipStyle = window.getComputedStyle(toolTip);
                        if (toolTipStyle.textAlign === 'right') {
                            toolTip.classList.add('tooltip-text-right');
                        }

                        const toolTipObj = new Tooltip(toolTip, { //eslint-disable-line
                            placement: 'bottom',
                            title: window.BOLD.recurring_orders.language.display_settings.hover_modal,
                            html: true,
                            template: constants.HTML_TOOLTIP_TEMPLATE,
                            trigger: window.BOLD.recurring_orders.settings.toolTipTrigger,
                        });
                    }
                }
                this.app.ee.emit('cart_widget_loaded', cartDiv);
            });
        }
        this.app.ee.emit('cart_widget_render_finished');
    }

    buildTemplateObject() {
        const { BOLD: { recurring_orders: { language } } } = window;
        const cart = window.BOLD.rawCart || window.BOLD.common.Shopify.cart;

        const templateObj = {
            translate: () => (text, render) => render(language.translations[render(text)]),
            weekdays: () => (text, render) => render(language.days_of_week[parseInt(render(text), 10)]),
            intervals: this.cartSettings.intervals,
            billingPlans: this.cartSettings.billing_plans,
            oneInterval: this.cartSettings.intervals.length === 1,
            detailsTooltip: this.cartSettings.details_tooltip,
        };

        templateObj.oneTimePrice = () => (text, render) => {
            const oneTimePrice = ShopifyHelper.displayMoney(cart.total_price, this.cartSettings.money_format);
            return render(render(text).replace(
                /\[one_time_price\]/g,
                `<span class="bold-ro__one-time-price">${oneTimePrice}</span>`,
            ));
        };

        templateObj.discountText = '';

        if (this.cartSettings.discount > 0) {
            const discountedTotalCartPrice = calculateDiscountedCartPrice(this.cartSettings.discount, cart.items);
            templateObj.discountText = language?.translations.discount
                .replace('[discount_percentage]', `${Math.round(this.cartSettings.discount, 2)}%`)
                .replace('[discount_price]', `<span class="new_discounted_price">${
                    ShopifyHelper.displayMoney(
                        discountedTotalCartPrice,
                        this.cartSettings.money_format,
                    )
                }</span>`);
        }

        // only weeks selected with billing plans
        if (
            this.cartSettings.intervals.length === 1
            && this.cartSettings.intervals[0].interval_id === '2'
            && this.cartSettings.billing_plans.length > 0
        ) {
            templateObj.weeklyBilling = true;

            templateObj.weeklyBillingTemplate = () => (text, render) => {
                const intervalNumber = `<select class="frequency_num" name="frequency_num">
                    {{#maxLength}}<option value="{{.}}">{{.}}</option>{{/maxLength}}
                </select>`;

                const interval = `<span>${language.translations[this.cartSettings.intervals[0].interval_type]}</span>`;

                let billingDay;
                if (this.cartSettings.billing_plans.length > 1) {
                    billingDay = `<select class="billing_plan_select" name="billing_plan" style="display:inline-block">
                        {{#billingPlans}}
                            <option value="{{id}}">{{#weekdays}}{{billing_day}}{{/weekdays}}</option>
                        {{/billingPlans}}
                    </select>`;
                } else {
                    billingDay = '{{#billingPlans}}{{#weekdays}}{{billing_day}}{{/weekdays}}{{/billingPlans}}';
                }

                return render(language.translations.billing_plan_select
                    .replace('[interval_number]', intervalNumber)
                    .replace('[interval]', interval)
                    .replace('[billing_day]', billingDay));
            };
        }

        // fill the max length array for the templates
        templateObj.maxLength = [];
        for (let i = 0; i < this.cartSettings.max_number; i += 1) { templateObj.maxLength.push(i + 1); }

        return templateObj;
    }

    bindEvents() {
        delegateEvent('change', `.${constants.CART_DIV_CLASS} [name="frequency_num"]`, (e) => {
            this.formParams.frequency_num = e.target.value;
        });
        delegateEvent('change', `.${constants.CART_DIV_CLASS} [name="billing_plan"]`, (e) => {
            this.formParams.billing_plan = e.target.value;
        });
        delegateEvent('change', `.${constants.CART_DIV_CLASS} [name="frequency_type"]`, (e) => {
            this.formParams.frequency_type = e.target.value;
            this.formParams.frequency_type_text = e.target.options[e.target.selectedIndex].text;
        });

        delegateEvent('change', `.${constants.CART_DIV_CLASS} [name="recurring_radio_btn"]`, (e) => {
            const cartDiv = e.target.closest(`.${constants.CART_DIV_CLASS}`);
            const oneTimeDiv = cartDiv.querySelector('.bold-ro__one-time-div');
            const recurringDiv = cartDiv.querySelector('.bold-ro__recurring-div');
            const recurringSettingsContainer = cartDiv.querySelector('#recurring-settings-container');
            this.app.ee.emit('recurring_cart_changed', { isRecurringCart: parseInt(e.target.value, 10) });

            if (e.target.value === '1') {
                this.recurringSelected = 1;

                oneTimeDiv.classList.remove('bold-ro__bold-active');
                recurringDiv.classList.add('bold-ro__bold-active');
                recurringSettingsContainer.style.display = 'block';
            } else {
                this.recurringSelected = 0;

                oneTimeDiv.classList.add('bold-ro__bold-active');
                recurringDiv.classList.remove('bold-ro__bold-active');
                recurringSettingsContainer.style.display = 'none';
            }
        });

        this.addCheckoutEvent('click', '[href*="checkout"]:not([href*="tools/checkout"])');
        this.addCheckoutEvent('click', '[name="checkout"]');
        this.addCheckoutEvent('click', '[onclick*="checkout"]:not([onclick*="tools/checkout"])');
        this.app.ee.onOut('BOLD_COMMON_refresh', this.setRecurringProductsInCart.bind(this));

        if (window.BOLD && window.BOLD.checkout) {
            // Disable duplicate checkout event listeners.
            window.BOLD.checkout.disable();
        }
    }

    addCheckoutEvent(eventName, selector) {
        unbindDelegateEvent(eventName, selector, this.checkRecurringCheckout.bind(this));
        delegateEvent(eventName, selector, this.checkRecurringCheckout.bind(this));
    }

    removeCheckoutEvent(eventName, selector) {
        unbindDelegateEvent(eventName, selector, this.checkRecurringCheckout.bind(this));
    }

    preCheckoutPromiseFunction(resolve) {
        resolve();
    }

    checkRecurringCheckout(e) {
        this.checkoutEvent = e;
        if (this.isCashierLinked && isCashierEnabled()) {
            if (isCashierAlwaysOn() || this.recurringSelected || (this.isMultiProduct && window.BOLD.checkout
                && window.BOLD.checkout.isEnabled())) {
                e.preventDefault();
                this.setRecurringProductsInCart()
                    .then(() => {
                        this.goToCashier(e);
                    });
            }
        } else {
            if (window.BOLD.checkout) {
                window.BOLD.checkout.disable();
            }
            if (this.recurringSelected || (this.isMultiProduct && !this.confirmedNoRecurringProducts)) {
                new Promise(this.preCheckoutPromiseFunction).then(this.finishCheckout.bind(this));
                e.preventDefault();
                return false;
            }
        }
        return true;
    }

    /**
     * Adds or updates a form element into form
     * @param form
     * @param name
     * @param value
     * @param type
     * @returns {null|Element}
     */
    addFormElement(form, name, value, type = 'hidden') {
        const selector = `[name=${name.replace(/(\[|\])/g, '\\$1')}]:last-child`;
        let element = form.querySelector(selector);

        if (!element) {
            element = document.createElement('input');
            element.type = type;
            element.name = name;
            form.appendChild(element);
        }
        element.value = value;

        return element;
    }

    goToCashier(e) {
        const form = e.target.closest('form');
        const cartCookie = getCookie('cart');
        form.setAttribute('method', 'post');
        this.addFormElement(form, 'cart_id', cartCookie);

        if (this.cartJSON && document.getElementById('CartSpecialInstructions')) {
            // If the cart note exists, grab its value
            this.cartJSON.note = document.getElementById('CartSpecialInstructions').value;
        }
        removeProductDescriptionsFromCart(this.cartJSON);
        this.addFormElement(form, 'cart', JSON.stringify(this.cartJSON));

        if (window.BOLD && window.BOLD.checkout && typeof window.BOLD.checkout.localTime === 'function') {
            this.addFormElement(form, 'checkout_local_time', window.BOLD.checkout.localTime());
        }

        if (window.BOLD && window.BOLD.checkout && typeof window.BOLD.checkout.languageIsoCode === 'string') {
            this.addFormElement(form, 'language_iso', window.BOLD.checkout.languageIsoCode);
        }

        if (this.recurringSelected) {
            this.addFormElement(form, `bold_cart_params[${this.applicationUUID}][recurring_selected]`, 1);
            this.addFormElement(form, `bold_cart_params[${this.applicationUUID}][frequency_num]`, this.formParams.frequency_num);
            this.addFormElement(form, `bold_cart_params[${this.applicationUUID}][frequency_type]`, this.formParams.frequency_type);
            this.addFormElement(form, `bold_cart_params[${this.applicationUUID}][frequency_type_text]`, this.formParams.frequency_type_text);
        } else {
            this.addFormElement(form, `bold_cart_params[${this.applicationUUID}][recurring_selected]`, 0);
        }

        googleAnalyticsGetParamString({ shop: window.BOLD.common.Shopify.shop.permanent_domain })
            .then((queryString) => {
                form.setAttribute('action', `/apps/checkout/begin-checkout${queryString}`);

                if (window.BOLD && window.BOLD.common && typeof window.BOLD.common.eventEmitter === 'object' && typeof BOLDCURRENCY !== 'undefined') {
                    window.BOLD.common.eventEmitter.emit('BOLD_CASHIER_checkout', { target: form });
                }

                form.submit();
            });
    }

    setRecurringProductsInCart() {
        changeCashierFeatureToken(false);

        return fetch(`${this.cartJSONUrl}?ts=${Date.now()}`, { credentials: 'same-origin' })
            .then((resp) => resp.json())
            .then((cartJSON) => {
                if (!cartJSON.items) {
                    throw new Error(cartJSON.errors || 'Cart items unavailable');
                }

                this.cartJSON = cartJSON;

                const recurringItemFound = cartJSON.items?.some((item) => item.properties?.frequency_type && item.properties?.group_id);

                if (recurringItemFound) {
                    changeCashierFeatureToken(true);
                }

                return recurringItemFound;
            });
    }

    finishCheckout() {
        this.setRecurringProductsInCart()
            .then((hasRecurringProducts) => {
                let action = this.recurringCartAction;
                if (this.isMultiProduct) {
                    action = this.multiProductAction;
                    this.confirmedNoRecurringProducts = !hasRecurringProducts;
                    if (this.confirmedNoRecurringProducts) {
                        if (this.checkoutEvent.type === 'submit') {
                            this.checkoutEvent.target.submit();
                        } else if (this.checkoutEvent.type === 'click') {
                            this.checkoutEvent.target.click();
                        }

                        return;
                    }
                } else if (this.recurringSelected || isCashierAlwaysOn()) {
                    changeCashierFeatureToken(true);
                }

                if (!this.cartSettings.anonymous_login
                    && (typeof window.BOLD.customer === 'undefined' || window.BOLD.customer.id === '')
                ) {
                    this.app.modal.open('#bold-ro_login-modal');
                    return;
                }

                if (!this.checkoutEnabled) {
                    return;
                }

                // create a hidden form to go to our checkout
                removeProductDescriptionsFromCart(this.cartJSON);
                this.formParams.pickup_enabled = this.pickupEnabled ? 1 : 0;
                this.formParams.shopify_cart = JSON.stringify(this.cartJSON);
                this.formParams.convertible_products = JSON.stringify(window.BOLD.recurring_orders.convertible_products);

                let form = this.checkoutEvent.target.closest('form');
                let appendNeeded = false;
                if (!form) {
                    form = document.createElement('form');
                    appendNeeded = true;
                }
                form.setAttribute('method', 'post');
                form.setAttribute('action', action);

                Object.keys(this.formParams).forEach((key) => {
                    if (Object.prototype.hasOwnProperty.call(this.formParams, key)) {
                        const hiddenField = createElementWithAttr('input', {
                            type: 'hidden',
                            name: key,
                            value: this.formParams[key],
                        });

                        form.appendChild(hiddenField);
                    }
                });

                if (appendNeeded) {
                    document.body.appendChild(form);
                }
                form.submit();
            });
    }
}
