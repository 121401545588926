const subscriptionBox = `
<div class="bold-ro__product bold-ro__product-id-{{product_id}}">
    <div class="bold-ro__recurring-title">
        {{#translate}}auto_replenish_product{{/translate}}
    </div>

    <div class="bold-ro__radio-div bold-ro__recurring-div {{#subscription_checked}}bold-ro__bold-active{{/subscription_checked}} bold-ro__sub">
        <label class="bold-ro__recurring-lbl">
            <input type="hidden" value="3" name="bold-ro__selector_radio_button" class="bold-ro__sub-box-radio-btn" checked="">
            <span class="bold-ro__recurring-text bold-ro__subscription-only"></span>
        </label>
        {{#details_tooltip}}<span class="bold-ro__see-details"><a href="javascript:;" class="bold-ro__detail-tooltip">{{#translate}}see_details{{/translate}}</a></span>{{/details_tooltip}}
        <div class="bold-ro__frequency-div">
            <div class="bold-ro__frequency-label">
                {{^has_billing_plan}}
                    {{^fixed_interval}}
                        {{#frequency_too_large}}
                            {{> customFrequencyInterval}}
                        {{/frequency_too_large}}
                        {{^frequency_too_large}}
                            {{> selectableFrequencyInterval}}
                        {{/frequency_too_large}}
                    {{/fixed_interval}}
                    
                    {{#fixed_interval}}
                        <span class="bold-ro__fixed-frequency-text" {{^subscription_checked}} style="display:none;" {{/subscription_checked}}>{{#translate}}order_will_ship_every{{/translate}} {{#merge_interval_number}}[interval_number]{{/merge_interval_number}} {{#merge_interval}}[interval]{{/merge_interval}}</span>
                        {{> fixedFrequencyInterval}}
                    {{/fixed_interval}}
                {{/has_billing_plan}}

                <input type="hidden" class="bold-ro__group-id" name="group_id" value="{{group_id}}">
                <input type="hidden" class="bold-ro__discounted-price" value="{{discount_price}}">
                <input type="hidden" class="bold-ro__discount-percentage" value="{{group_discount}}">
                <input type="hidden" class="bold-ro__unformatted-price" value="{{unformatted_discount_price}}">
                <input type="hidden" name="{{csrf_token_name}}" value="{{csrf_token}}">
                <input type="hidden" name="quantities[]" value="">
                <input type="hidden" name="product_id[]" value="{{product_id}}">
                <input type="hidden" name="variant_id[]" value="{{variant_id}}">
                <input type="hidden" name="note" value="">


                {{#has_billing_plan}}
                    {{#fixed_interval}}
                        {{> fixedFrequencyInterval}}
                    {{/fixed_interval}}
                    {{^fixed_interval}}
                        {{#frequency_type}}
                            <input type="hidden" name="frequency_type" class="bold-ro__frequency-type" value="{{frequency_type_id}}">
                            <input name="frequency_type_text" class="bold-ro__frequency-type-text" type="hidden" value="{{#translate}}{{frequency_type_translation}}{{/translate}}">
                        {{/frequency_type}}
                    {{/fixed_interval}}
                    {{> billingPlan}}
                {{/has_billing_plan}}

                {{#limited_subscription}}
                    {{> limitedLength}}
                {{/limited_subscription}}
            </div>
        </div>
    </div>
</div>
`;

export default subscriptionBox;
