function variantExists(productHandle, variantId) {
    let result = false;
    window.BOLD.recurring_orders.convertible_products[productHandle].variants.forEach((variant) => {
        if (variant.id == variantId) {
            result = true;
        }
    });
    return result;
}

function addConvertibleVariants(shopifyConvertibleProduct, convertibleProductHandle, convertibleVariantId) {
    shopifyConvertibleProduct.variants.forEach((variant) => {
        if (variant.id == convertibleVariantId && !variantExists(convertibleProductHandle, convertibleVariantId)) {
            window.BOLD.recurring_orders.convertible_products[convertibleProductHandle].variants.push({
                id: variant.id,
                title: variant.public_title,
                name: variant.name,
                price: variant.price,
            });
        }
    });
}

window.BOLD.recurring_orders.variantExists = variantExists;
window.BOLD.recurring_orders.addConvertibleVariants = addConvertibleVariants;
