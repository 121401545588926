import { ShopifyHelper } from '@bold-commerce/bold-common-js';
import { map } from 'lodash';
import {
    DATA_SHIPPING_LOADING,
    URL_CHECK_SHIPPING_RATES_PROXY,
    FORMDATA_KEY_PRODUCT_ID,
    FORMDATA_KEY_VARIANT_ID,
    FORMDATA_KEY_CSRF_TOKEN,
    FORMDATA_KEY_ORDER_ID,
    FORMDATA_KEY_PRODUCT,
    FORMDATA_KEY_TOKEN,
    FORMDATA_KEY_SIGNATURE,
    DATA_ATE_SHIPPING_CONTAINER,
    TEMPLATE_SHIPPING_METHODS,
    DATA_ATE_SUCCESS,
    DATA_ATE_ERROR,
    DATA_ATE_BUTTONS,
    DATA_LOADING,
    DATA_ATE_COMPLETE,
    URL_SAVE_PRODUCT_INTO_ORDER,
    ID_ATE_MODAL_CONTENT,
    USER_ACTIVITY_LOG_ADD_PRODUCT,
    DATA_ORDERS_SELECT,
    TEMPLATE_ADD_TO_EXISTING,
    DATA_ATE_FORM,
    DATA_ATE_ADD_BUTTON,
    NAME_SHIPPING_RATE,
    OPEN_ADD_TO_EXISTING,
    CLASS_SELECTOR_RECURRING_RADIO_BUTTON,
} from '../constants/index';
import {
    commonMergeFieldFunctions,
    getFormQuantity,
    shippingPriceToFloat,
    getPropsFromForm,
    getFormArray,
    getProp,
    addExistingOrdersButton,
    showAddToExistingButton,
    hideAddToExistingButton,
    isRecurringCartMode,
} from './HelperFunctions';
import DOMHandler from './DOMHandler';

export default class RecurringOrdersAddToExisting {
    constructor(app) {
        this.app = app;
        this.addExistingOrdersButton = addExistingOrdersButton;
        this.showAddToExistingButton = showAddToExistingButton;
        this.hideAddToExistingButton = hideAddToExistingButton;
        this.bindEvents();
    }

    bindEvents() {
        this.app.ee.on('add_product_to_order', this.addProductToOrder, this);
        this.app.ee.on('open_add_to_order', this.addToOrderButtonClicked, this);
        this.app.ee.on('order_changed', this.getShippingRates, this);
        this.app.ee.on('orders_loaded', this.showButtons, this);
    }

    /**
     * On Click of the add to existing button this function will be called.
     *  The element will get created and the modal will be trigged to open
     */
    render() {
        if (window.BOLD
            && window.BOLD.customer
            && window.BOLD.customer.id
            && !Number.isNaN(Number(window.BOLD.customer.id))
            && Number(window.BOLD.customer.id) !== 0
            && window.BOLD.customer.id !== undefined
            && window.BOLD.customer.id !== null
        ) {
            this.getExistingOrders().then((fields) => {
                const formatMoney = (value, exchangeRate, currencyFormat) => {
                    const storeCurrencyFormat = window.BOLD.common.Shopify.shop.money_format;
                    const enableMulticurrencyApp = (window.BOLDCURRENCY && window.BOLDCURRENCY.enabled && window.BOLDCURRENCY.converter
                        && (!(window.BOLDCURRENCY.defaultShopCurrency === '')) && window.BOLDCURRENCY.currentCurrency);
                    exchangeRate = exchangeRate > 0 ? exchangeRate : 1;

                    // MC disabled or currency format empty
                    if (!fields.mc_enabled || !currencyFormat) {
                        exchangeRate = 1;
                        currencyFormat = storeCurrencyFormat;
                    } else if (enableMulticurrencyApp) {
                        value = window.BOLDCURRENCY.converter.convertPriceWithRules(value, window.BOLDCURRENCY.currentCurrency);
                        currencyFormat = window.BOLDCURRENCY.moneyFormats[window.BOLDCURRENCY.currentCurrency].money_with_currency_format;
                    }

                    return window.BOLD.common.Shopify.formatMoney(parseFloat((value * exchangeRate * 100).toFixed(2)), currencyFormat);
                };

                if (fields.orders.length > 0) {
                    this.fields = {
                        token: fields.token, signature: fields.signature, orders: fields.orders, csrf_token: fields.csrf_token,
                    };
                    fields.greaterThanOneOrder = fields.orders.length > 1;
                    const optionTranslation = window.BOLD.recurring_orders.language.translations.order_information;
                    fields.addMultipleOrders = () => (text, render) => {
                        let optionsList = '';
                        map(fields.orders, (order, index) => {
                            const convertedSubtotal = formatMoney(order.subtotal, order.currency_exchange_rate, order.currency_format);
                            const interval = `${order.interval_number} ${window.BOLD.recurring_orders.language.translations[order.interval_type]}`;
                            const optionText = optionTranslation.replace(/\[product_quantity\]/g, order.counter)
                                .replace(/\[subtotal\]/g, convertedSubtotal)
                                .replace(/\[order_number\]/g, index + 1)
                                .replace(/\[interval\]/g, interval);
                            optionsList += `<option value="${order.order_id}">${optionText}</option>`;
                        });
                        return render(optionsList);
                    };
                    fields.addSingleOrder = () => (text, render) => {
                        const order = fields.orders[0];
                        const convertedSubtotal = formatMoney(order.subtotal, order.currency_exchange_rate, order.currency_format);
                        const interval = `${order.interval_number} ${window.BOLD.recurring_orders.language.translations[order.interval_type]}`;
                        const orderText = optionTranslation.replace(/\[product_quantity\]/g, order.counter)
                            .replace(/\[subtotal\]/g, convertedSubtotal)
                            .replace(/\[order_number\]/g, 1)
                            .replace(/\[interval\]/g, interval);
                        return render(orderText);
                    };
                    fields.translate = () => (text, render) => {
                        const result = window.BOLD.recurring_orders.language.translations[render(text)];
                        return render(result);
                    };

                    if (typeof this.DOMAddToExist === 'undefined') {
                        // TODO: Potentially add promise here to make sure dom elements are renedered before events are generated
                        const modalContainerElement = document.querySelector('.bold-ro__modal-container');
                        if (modalContainerElement) {
                            this.DOMAddToExist = new DOMHandler(TEMPLATE_ADD_TO_EXISTING, fields, modalContainerElement);
                        }
                    } else {
                        // refresh add to existing template
                        this.DOMAddToExist.setFields(fields);
                        this.DOMAddToExist.templateType = TEMPLATE_ADD_TO_EXISTING;
                        this.DOMAddToExist.refreshTemplate();
                        this.app.ee.emit('add_to_existing_template_refreshed');
                    }
                    this.app.ee.emit('orders_loaded');
                }
            }).catch((e) => {
                console.error(`Unable to receive a response from https://${window.BOLD.common.Shopify.shop.domain}/tools/checkout/api_public/orders/${window.BOLD.customer.id}?shop_url=${ShopifyHelper.getShopUrl()}. Error: `, e);
            });
        }
    }

    addToOrderButtonClicked(ctrl) {
        const {
            data: {
                productId, variantId, quantity, form,
            },
        } = ctrl;
        if (!this.app.modal.isOpen && this.hasOrders()) {
            const modalForm = document.querySelector(DATA_ATE_FORM);
            if (modalForm) {
                modalForm.product_id.value = productId;
                modalForm.variant_id.value = variantId;
                modalForm.quantity.value = quantity;
                modalForm.properties.value = JSON.stringify(getPropsFromForm(form));
                modalForm.bold_signature.value = this.fields.signature;
                modalForm.bold_token.value = this.fields.token;
                modalForm.csrf_bold_token.value = this.fields.csrf_token;
                modalForm.removeAttribute('style');
            }
            const modalButtons = document.querySelector(DATA_ATE_BUTTONS);
            if (modalButtons) {
                modalButtons.removeAttribute('style');
            }

            // Hide Completed elements
            const completeButtons = document.querySelector(DATA_ATE_COMPLETE);
            if (completeButtons) {
                completeButtons.style.display = 'none';
            }

            this.app.modal.open(ID_ATE_MODAL_CONTENT);
            const ordersElem = document.querySelector(DATA_ORDERS_SELECT);
            const ordersFormElem = document.querySelector(DATA_ATE_FORM);
            if (ordersElem && !this.loadingShippingRates) {
                this.app.ee.emit('order_changed', {
                    orderId: ordersElem.value,
                    form: ordersFormElem,
                });
            }
            const addProductElem = document.querySelector(DATA_ATE_ADD_BUTTON);
            if (addProductElem) {
                addProductElem.disabled = 'disabled';
            }
        }
    }

    addProductToOrder() {
        const form = document.querySelector(DATA_ATE_FORM);
        let formData;
        if (form) {
            formData = new FormData(form);
            form.style.display = 'none';
        } else {
            const addSuccessElem = document.querySelector(DATA_ATE_SUCCESS);
            if (addSuccessElem) {
                addSuccessElem.style.display = 'none';
            }
            const addErrorElem = document.querySelector(DATA_ATE_ERROR);
            if (addErrorElem) {
                addErrorElem.removeAttribute('style');
            }
            return;
        }
        // Hide Orders and Buttons
        const modalButtons = document.querySelector(DATA_ATE_BUTTONS);
        if (modalButtons) {
            modalButtons.style.display = 'none';
        }

        // Display Loading animation
        const loading = document.querySelector(DATA_LOADING);
        if (loading) {
            loading.removeAttribute('style');
        }
        const path = `https://${window.BOLD.common.Shopify.shop.domain}${URL_SAVE_PRODUCT_INTO_ORDER}`;
        fetch(path, {
            method: 'POST',
            body: formData,
        }).then((resp) => {
            // Hide Loading animation
            loading.style.display = 'none';
            // Display Success or error message after add attempt
            const completeButtons = document.querySelector(DATA_ATE_COMPLETE);
            if (completeButtons) {
                completeButtons.removeAttribute('style');
            }
            if (resp.status === 200) {
                resp.json().then((res) => {
                    // Add is successful then display success message if not display error message
                    if (res.success) {
                        const addSuccessElem = document.querySelectorAll(DATA_ATE_SUCCESS);
                        map(addSuccessElem, (elem) => {
                            elem.removeAttribute('style');
                        });
                        const addErrorElem = document.querySelector(DATA_ATE_ERROR);
                        if (addErrorElem) {
                            addErrorElem.style.display = 'none';
                        }
                    } else {
                        const addSuccessElem = document.querySelectorAll(DATA_ATE_SUCCESS);
                        map(addSuccessElem, (elem) => {
                            elem.style.display = 'none';
                        });
                        const addErrorElem = document.querySelector(DATA_ATE_ERROR);
                        if (addErrorElem) {
                            addErrorElem.removeAttribute('style');
                        }
                    }
                });
            } else {
                const addSuccessElem = document.querySelectorAll(DATA_ATE_SUCCESS);
                map(addSuccessElem, (elem) => {
                    elem.style.display = 'none';
                });
                const addErrorElem = document.querySelector(DATA_ATE_ERROR);
                if (addErrorElem) {
                    addErrorElem.removeAttribute('style');
                }
            }
            // Refresh Orders
            this.render();
        });
    }

    getShippingRates(data) {
        const { data: { orderId, form } } = data;
        const modalData = getFormArray(form);

        const formData = new FormData();
        const product = {
            0: {
                product_id: getProp(modalData, FORMDATA_KEY_PRODUCT_ID),
                variant_id: getProp(modalData, FORMDATA_KEY_VARIANT_ID),
                quantity: getFormQuantity(form),
                parent_properties: getPropsFromForm(form),
            },
        };
        formData.append(FORMDATA_KEY_CSRF_TOKEN, getProp(modalData, FORMDATA_KEY_CSRF_TOKEN));
        formData.append(FORMDATA_KEY_ORDER_ID, orderId);
        formData.append(FORMDATA_KEY_PRODUCT, JSON.stringify(product));
        formData.append(FORMDATA_KEY_TOKEN, this.fields.token);
        formData.append(FORMDATA_KEY_SIGNATURE, this.fields.signature);
        const addProductElem = document.querySelector(DATA_ATE_ADD_BUTTON);
        if (addProductElem) {
            addProductElem.setAttribute('disabled', 'disabled');
        }
        const ordersSelect = document.querySelector(DATA_ORDERS_SELECT);
        if (ordersSelect) {
            ordersSelect.setAttribute('disabled', 'disabled');
        }
        const shippingLoadingElem = document.querySelectorAll(DATA_SHIPPING_LOADING);
        map(shippingLoadingElem, (elem) => {
            elem.removeAttribute('style');
        });
        if (typeof this.DOMShippingMethods !== 'undefined'
            && typeof this.DOMShippingMethods.element !== 'undefined') {
            this.DOMShippingMethods.element.remove();
        }
        this.loadingShippingRates = true;
        const path = `https://${window.BOLD.common.Shopify.shop.domain}${URL_CHECK_SHIPPING_RATES_PROXY}/${orderId}/${USER_ACTIVITY_LOG_ADD_PRODUCT}`;
        fetch(path, {
            method: 'POST',
            body: formData,
        }).then((resp) => {
            resp.json().then((shippingMethodsResp) => {
                shippingMethodsResp.shipping_rates = shippingPriceToFloat(shippingMethodsResp.shipping_rates);
                if (shippingMethodsResp.shipping_rates.length > 0) {
                    shippingMethodsResp.shipping_rates[0].checked = true;
                }
                this.renderShippingRates(shippingLoadingElem, addProductElem, ordersSelect, shippingMethodsResp);
            }).catch((e) => {
                this.handleShippingRateError(shippingLoadingElem, addProductElem, ordersSelect, orderId, e);
            });
        }).catch((e) => {
            this.handleShippingRateError(shippingLoadingElem, addProductElem, ordersSelect, orderId, e);
        });
    }

    handleShippingRateError(shippingLoadingElem, addProductElem, ordersSelect, orderId, error) {
        const fields = { error: true };
        this.renderShippingRates(shippingLoadingElem, addProductElem, ordersSelect, fields);
        this.updateOrderIdForBlankShippingRate(ordersSelect.value);
        console.error(`Unable to receive a response from https://${window.BOLD.common.Shopify.shop.domain}${URL_CHECK_SHIPPING_RATES_PROXY}/${orderId}/${USER_ACTIVITY_LOG_ADD_PRODUCT}.`, error);
    }

    renderShippingRates(shippingLoadingElem, addProductElem, ordersSelect, shippingMethodsResp = {}) {
        this.loadingShippingRates = false;
        shippingMethodsResp = commonMergeFieldFunctions(shippingMethodsResp);
        const addToOrderFormElem = document.querySelector(DATA_ATE_FORM);
        if (addToOrderFormElem) {
            map(shippingLoadingElem, (elem) => {
                elem.style.display = 'none';
            });
            const shippingRates = document.querySelectorAll(DATA_ATE_SHIPPING_CONTAINER);
            map(shippingRates, (rate) => {
                rate.remove();
            });
            this.DOMShippingMethods = new DOMHandler(TEMPLATE_SHIPPING_METHODS, shippingMethodsResp, addToOrderFormElem);
            this.app.ee.emit('template_shipping_methods_initialized');
            if (addProductElem) {
                addProductElem.removeAttribute('disabled');
            }
            if (ordersSelect) {
                ordersSelect.removeAttribute('disabled');
            }
        }
    }

    hasOrders() {
        let result = false;
        if (typeof this.fields !== 'undefined' && typeof this.fields.orders !== 'undefined' && this.fields.orders.length > 0) {
            result = true;
        }
        return result;
    }

    updateOrderIdForBlankShippingRate(orderId) {
        const shippingRateInput = document.querySelectorAll(NAME_SHIPPING_RATE);
        if (shippingRateInput) {
            map(shippingRateInput, (rate) => {
                rate.value = `{&quot;bold_order_id&quot;:&quot;${orderId}&quot;,&quot;code&quot;:&quot;&quot;,&quot;name&quot;:&quot;&quot;,&quot;source&quot;:&quot;&quot;,&quot;price&quot;:&quot;&quot;,&quot;translated_name&quot;:&quot;&quot;,&quot;tax_rows&quot;:null,&quot;hash&quot;:&quot;&quot;}`;
            });
        }
    }

    getExistingOrders() {
        const shopifyCustomerId = Number(window.BOLD.customer.id);
        return this.app.api.retrieve({ endpoint: `orders/${shopifyCustomerId}`, noCache: true, proxyRequest: true });
    }

    /**
     * After the render function is run and there are orders for the logged in customer, this function is used
     *  to display the add to existing buttons on all forms that have the recurring option selected or if
     *   the mode is set to Recurring Cart.
     */
    showButtons() {
        const buttons = document.querySelectorAll(OPEN_ADD_TO_EXISTING);
        if (buttons) {
            map(buttons, (button) => {
                const form = button.closest('form');
                if (form) {
                    const recurringButton = form.querySelector(CLASS_SELECTOR_RECURRING_RADIO_BUTTON);
                    const isValid = (recurringButton && recurringButton.checked) || (isRecurringCartMode());
                    if (isValid) {
                        showAddToExistingButton(form);
                    }
                }
            });
        }
    }
}
