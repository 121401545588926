const addToExisting = `
<div class="bold-ro__modal-hider" data-add-to-existing-modal id="bold-ro__add-to-existing-modal" style="display:none;">

    <h3>{{#translate}}add_product_title{{/translate}}</h3>

    <form data-bold-form-add-to-order id="bold-add-to-existing">
        {{#greaterThanOneOrder}}
            <p>{{#translate}}multiple_order_description{{/translate}}</p>
            <select data-existing-orders name="bold_order_id" class="bold-ro__existing-orders">    
            {{#addMultipleOrders}}{{/addMultipleOrders}}    
            </select>
        {{/greaterThanOneOrder}}
        
        {{^greaterThanOneOrder}}
            <label>{{#addSingleOrder}}{{/addSingleOrder}}</label>
            {{#orders}}
            <input data-existing-orders type="hidden" name="bold_order_id" class="bold-ro__existing-orders" value="{{order_id}}">
            {{/orders}}
        {{/greaterThanOneOrder}}

        <input type="hidden" name="product_id" value=""/>
        <input type="hidden" name="variant_id" value=""/>
        <input type="hidden" name="quantity" value=""/>    
        <input type="hidden" name="properties" value=""/>
        <input type="hidden" name="bold_signature" value=""/>
        <input type="hidden" name="bold_token" value=""/>
        <input type="hidden" name="csrf_bold_token" value=""/>
    </form>
    <div data-shipping-loading class="bold-ro__shipping-loading">
        <p class="bold-ro__loading-shipping-text">{{#translate}}add_to_existing_ship_loading{{/translate}}</p>
    </div>
    <div data-shipping-loading data-bold-loading class="bold-ro__loading">{{> widgetLoading}}</div>
    
    <div class="bold-ro__ate-buttons" data-bold-ate-add-buttons>
        <button data-bold-modal-close class="btn bold-ro__cancel-ate">{{#translate}}cancel{{/translate}}</button>
        <button data-add-to-existing disabled="disabled" class="btn bold-ro__add-ate">{{#translate}}add{{/translate}}</button>        
    </div>
    <div data-bold-ate-complete style="display:none;">
        <div data-bold-add-success class="bold-ro__fadeIn icon icon--order-success svg">
            <svg xmlns="http://www.w3.org/2000/svg" width="72px" height="72px">
                <g fill="none" stroke="#8EC343" stroke-width="2">
                    <circle cx="36" cy="36" r="35" style="stroke-dasharray:240px, 240px; stroke-dashoffset: 480px;"></circle>
                    <path d="M17.417,37.778l9.93,9.909l25.444-25.393" style="stroke-dasharray:50px, 50px; stroke-dashoffset: 0px;"></path>
                </g>
            </svg>
        </div>
        <h4 data-bold-add-success class="bold-ro__fadeIn">{{#translate}}add_successfully{{/translate}}</h4>
        <h4 data-bold-add-error class="bold-ro__fadeIn">{{#translate}}add_to_existing_add_error{{/translate}}</h4>
        <button data-bold-modal-close data-bold-modal-ok onclick="location.href='/tools/checkout/front_end/login'" class="btn bold-ro__ok-ate">{{#translate}}add_to_existing_view_order{{/translate}}</button>
    </div>
</div>
`;

export default addToExisting;
