const limitedLength = `
<div class="bold-ro__limited-length-container" {{^subscription_checked}} style="display:none;" {{/subscription_checked}}>
    <span class="bold-ro__limited-text">{{#translate}}subscription_length{{/translate}}</span>
    <select name="total_recurrences" class="bold-ro__limited-length-select bold-ro__subscription-length" {{#is_prepaid_only}}style="display:none"{{/is_prepaid_only}}>
            {{#limited_subscription.lengths}}
        <option value="{{.}}">{{.}}</option>
            {{/limited_subscription.lengths}}
            {{#limited_subscription.ongoing}}
        <option value="">{{#translate}}no_limit{{/translate}}</option>
            {{/limited_subscription.ongoing}}
    </select>
</div>
`;

export default limitedLength;
