const recurringCart = `
<div class="bold-ro__product">
  <div class="bold-ro__recurring-title">{{#translate}}auto_replenish_cart{{/translate}}</div>
  <div class="bold-ro__radio-div bold-ro__one-time-div bold-ro__bold-active">
    <label class="bold-ro__recurring-lbl" style="display:inline-block">
      <input type="radio" value="0" name="recurring_radio_btn" class="bold-ro__recurring-radio-btn" checked>
      {{#oneTimePrice}}{{#translate}}one_time_purchase{{/translate}}{{/oneTimePrice}}
    </label>
  </div>
  <div class="bold-ro__radio-div bold-ro__recurring-div bold-ro__sub">
    <label class="bold-ro__recurring-lbl">
      <input type="radio" value="1" name="recurring_radio_btn" class="bold-ro__recurring-radio-btn">
      {{#translate}}widget_repenish{{/translate}}{{{discountText}}}
      {{#detailsTooltip.location.inline}}{{#detailsTooltip.enabled}}<a href="#nogo" class="bold-ro__detail-tooltip" >{{#translate}}see_details{{/translate}}</a>{{/detailsTooltip.enabled}}{{/detailsTooltip.location.inline}}
    </label>
    <div id="recurring-settings-container" style="display:none;">
      <div class="frequency_label">
        {{#weeklyBilling}}
          {{#weeklyBillingTemplate}}{{/weeklyBillingTemplate}}
        {{/weeklyBilling}}
        {{^weeklyBilling}}
          {{#translate}}deliver_every{{/translate}}
          <select class="frequency_num" name="frequency_num">
            {{#maxLength}}
              <option value="{{.}}">{{.}}</option>
            {{/maxLength}}
          </select>
          {{#oneInterval}}
            <span style="padding-left:10px">
              {{#intervals}}
                {{#translate}}{{interval_type}}{{/translate}}
              {{/intervals}}
            </span>
          {{/oneInterval}}
          {{^oneInterval}}
            <select class="frequency_type" name="frequency_type">
              {{#intervals}}
                <option value="{{default_id}}">{{#translate}}{{interval_type}}{{/translate}}</option>
              {{/intervals}}
            </select>
          {{/oneInterval}}
        {{/weeklyBilling}}
      </div>
    </div>
  </div>
  {{#detailsTooltip.location.bottom}}{{#detailsTooltip.enabled}}<a href="#nogo" class="bold-ro__detail-tooltip" >{{#translate}}see_details{{/translate}}</a>{{/detailsTooltip.enabled}}{{/detailsTooltip.location.bottom}}
</div>
`;

export default recurringCart;
