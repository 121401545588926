import { JSHelper } from '@bold-commerce/bold-common-js';
import map from 'lodash/map';

function initSettings(settings, overwrite = true, settingsPath = 'BOLD.recurring_orders.settings') {
    map(settings, (val, key) => {
        const path = `${settingsPath}.${key}`;
        if (JSHelper.type(val) === 'object') {
            initSettings(val, overwrite, path);
        } else {
            JSHelper.windowSet(path, settings[key], overwrite);
        }
    });
}

initSettings({
    loadMode: 'blacklist',
    loadBlacklist: ['customers/*'],
    loadWhitelist: ['product', 'collection', 'search', 'index'],
    clearCartBlacklist: ['customers/login'],
    loadOnDuplicateProductForms: false,
    loadCustomButtonClasses: 'btn button',
    loadAddToExistingButtonClasses: 'btn button',
    loadAdditionalCheckoutSelector: '.additional-checkout-buttons',
    loadAdditionalBuyButtonSelector: '',
    useAddToCartClasses: false,
    loadingEnabled: false,
    previewEnabled: false,
    toolTipTrigger: 'click hover',
    toolTipLocation: 'bottom',
    resetSubscriptionStateOnReboot: false,
}, false);

window.BOLD.recurring_orders.setSettings = initSettings;

export default initSettings;
