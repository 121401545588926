import {
    JSHelper, ShopifyHelper, BoldHelpers, BoldEventEmitter, BoldCartDoctor, BoldCartPropTemplate,
} from '@bold-commerce/bold-common-js';
import map from 'lodash/map';
import filter from 'lodash/filter';
import VanillaModal from 'vanilla-modal';
import Mustache from 'mustache';
import RecurringOrdersProduct from '../ProductClasses/RecurringOrdersProduct';
import RecurringOrdersAddToExisting from './RecurringOrdersAddToExisting';
import RecurringOrdersCart from './RecurringOrdersCart';
import cartLineItems from '../templates/cartlineitems';
import {
    RO_CLASS_IDENTIFIER,
    VARIANT_DOM_SELECTOR,
    RO_NO_WIDGET_CLASS_NAME,
    DATA_ATE_FORM,
    OPEN_ADD_TO_EXISTING,
    DATA_ATE_ADD_BUTTON,
    DATA_WIDGET_LOADING,
    TEMPLATE_WIDGET_LOADING,
} from '../constants/index';
import {
    createStyleSheet,
    loadPolyfills,
    loadTranslations,
    delegateEvent,
    toggleAdditionalCheckout,
    isValidGroup,
    calculateDiscountPercentPrice,
    toggleBuyNowButton,
} from './HelperFunctions';
import recurringOrdersAPI from './RecurringOrdersAPI';
import DOMHandler from './DOMHandler';

class RecurringOrdersBootstrap {
    constructor() {
        window.BOLD.recurring_orders.app = this;
        this.reinstateShopifyCartToken();

        this.api = recurringOrdersAPI;
        this.cartWidget = new RecurringOrdersCart(this);
        this.cartWidget.init();
        this.page = ShopifyHelper.whatPageAmIOn();
        this.ee = new BoldEventEmitter(this.page, 'RECURRING_ORDERS');
        this.cartDoctor = RecurringOrdersBootstrap.loadCartDoctor();
        this.recurringOrderProducts = [];
        this.DOMLoadingElements = [];
        this.productForms = [];

        this.isDomLoaded = false;
        this.settings = window.BOLD.recurring_orders.settings;

        this.polyfills = [];
        BoldCartPropTemplate.cart = window.BOLD.common.Shopify.cart;
        this.addToExisting = new RecurringOrdersAddToExisting(this);
        this.bindEvents();
        this.boot();
    }

    reinstateShopifyCartToken() {
        if (typeof URLSearchParams === 'undefined') {
            return;
        }
        const searchParams = new URLSearchParams(window.location.search);
        if (!searchParams.has('bold-cart-token')) {
            return;
        }
        const newCartToken = searchParams.get('bold-cart-token');
        searchParams.delete('bold-cart-token');

        if (!newCartToken || newCartToken.trim().length <= 0) {
            return;
        }
        // Update the current cart cookie - reinstates the cart.

        // Set the cart token to expire so the cookies dont exist for too long. (3 hours = 10800 sec)
        document.cookie = `cart=${newCartToken}; SameSite=Lax; max-age=10800;`;
        // Update the URL and reload the page - will display the new "cart"
        const newQueryString = searchParams.toString();
        // send the user back to the page they were on
        let updateCartUrl = window.location.pathname;
        if (newQueryString && newQueryString.trim().length > 0) {
            updateCartUrl += `?${newQueryString}`;
        }
        window.location.replace(updateCartUrl);
    }

    bindEvents() {
        this.ee.onOut('BOLD_COMMON_variant_changed', () => {
            this.ee.emit('variant_changed');
        });
        this.ee.on('ro_required_login', this.login, this);
        this.ee.on('products_loaded', () => { loadPolyfills(this.polyfills); });
        this.ee.on('recurring_cart_changed', toggleAdditionalCheckout);
        this.ee.on('dom_loaded', toggleAdditionalCheckout);
        this.ee.on('single_product_recurring', toggleBuyNowButton);
        this.ee.on('mixed_product_recurring', toggleBuyNowButton);
        this.ee.on('one_time_product_selected', toggleBuyNowButton);
        this.ee.onOut('BOLD_COMMON_cart_loaded', (evt) => {
            if (JSHelper.type(evt) === 'object' && typeof evt.total_price !== 'undefined' && !evt.is_fixed) {
                this.cartDoctor = RecurringOrdersBootstrap.loadCartDoctor(evt);
                this.bootCart(false);
            } else {
                this.bootCart();
            }
        });

        document.addEventListener('DOMContentLoaded', () => {
            this.addLoading();
            this.isDomLoaded = true;
            this.ee.emit('dom_loaded');
            this.addToExistingEvents();
        });
        // replaces selectCallback function that some themes create
        // with a bold function that emits events before and after
        // the selectCallback function is called
        this.onDomLoaded(BoldHelpers.selectCallbackAddEvent.bind(BoldHelpers));

        window.addEventListener('keydown', (e) => {
            let result = true;
            if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) {
                if (e.target.nodeName == 'INPUT' && ((e.target.name == 'quantity' && (e.target.type == 'text' || e.target.type == 'number')) || (e.target.type == 'date'))) {
                    e.preventDefault();
                    result = false;
                }
            }
            return result;
        }, true);
    }

    bootCart(requestUpdatedCart = true) {
        if (requestUpdatedCart) {
            fetch('/cart.json', { credentials: 'same-origin' }).then((resp) => resp.json())
                .then((cartJson) => {
                    this.cartDoctor = RecurringOrdersBootstrap.loadCartDoctor(cartJson);
                    this.onDomLoaded(() => {
                        this.cartDoctor.updateCart();
                        this.renderLineItems(cartJson);
                    });
                });
        } else {
            this.onDomLoaded(() => {
                this.cartDoctor.updateCart();
                this.renderLineItems();
            });
        }
    }

    addToExistingEvents() {
        delegateEvent('change', '[data-existing-orders]', (e) => {
            const selectedOrder = e.target.value;
            this.ee.emit('order_changed', {
                orderId: selectedOrder,
                form: document.querySelector(DATA_ATE_FORM),
            });
        });
        delegateEvent('click', OPEN_ADD_TO_EXISTING, (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            const parentForm = e.target.closest('form');
            let formQuantity = 1;
            const formQtyElem = parentForm.quantity;
            if (formQtyElem) {
                formQuantity = formQtyElem.value;
            }
            this.ee.emit('open_add_to_order', {
                productId: window.BOLD.common.Shopify.variants[parentForm.id.value].product_id,
                variantId: parentForm.id.value,
                quantity: formQuantity,
                form: parentForm,
            });
        });
        delegateEvent('click', DATA_ATE_ADD_BUTTON, (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            // this.addProductToOrder();
            this.ee.emit('add_product_to_order');
        });
    }

    onDomLoaded(fn) {
        if (this.isDomLoaded) {
            fn();
        } else {
            this.ee.on('dom_loaded', fn);
        }
    }

    boot() {
        if (
            (this.settings.loadMode === 'blacklist' && !JSHelper.inArray(this.settings.loadBlacklist, this.page, true))
            || (this.settings.loadMode === 'whitelist' && JSHelper.inArray(this.settings.loadWhitelist, this.page, true))
        ) {
            this.onDomLoaded(() => {
                this.api.retrieve({ endpoint: 'css', responseType: 'text' })
                    .then((cssText) => { createStyleSheet(cssText); });
                this.loadModal();
                Promise.all([loadTranslations()])
                    .then((data) => {
                        if (data.error) {
                            throw data.error;
                        }
                        this.initializeWidget();
                        this.setupCartLinePropRenderer();
                    })
                    .catch((e) => {
                        console.error('loadTranslations', e);
                        this.stopLoading();
                    });
            });
        }

        this.removeSingleProductRecurringItems();
    }

    renderLineItems(cart = {}) {
        if (Object.keys(cart).length > 0) {
            BoldCartPropTemplate.cart = cart;
        }
        BoldCartPropTemplate.render();
    }

    setupCartLinePropRenderer() {
        if (document.querySelectorAll('.bold_recurring_desc:not([data-widget-2])').length > 0) {
            return;
        }
        BoldCartPropTemplate.registerRenderer((item) => {
            item.properties = item.properties_all ? item.properties_all : item.properties;
            if (item.properties && item.properties.frequency_type && item.properties.frequency_num && item.properties.frequency_type_text) {
                const { translations } = window.BOLD.recurring_orders.language;
                const templateObj = {};

                if (typeof item.properties._convertible_discount_percent !== 'undefined') {
                    templateObj.isConvertible = true;
                    templateObj.convertibleNameLineItem = item.properties._convertible_product_details ? item.properties._convertible_product_details : '';
                    templateObj.convertibleDeliverEveryLineItem = translations.convertible_deliver_every_line_item
                        .replace(
                            '[frequency_num]',
                            `<span class="ro-frequency-num">${item.properties.frequency_num}</span>`,
                        )
                        .replace(
                            '[frequency_type_text]',
                            `<span class="ro-frequency-type">${item.properties.frequency_type_text}</span>`,
                        );
                } else {
                    templateObj.isConvertible = false;
                    let itemPrice = item.original_price;

                    if (item.properties._ro_discount_percentage && !BoldCartPropTemplate.cart.is_fixed) {
                        itemPrice *= (100 - item.properties._ro_discount_percentage) * 0.01;
                    }

                    templateObj.deliverEveryLineItem = translations.deliver_every_line_item
                        .replace(
                            '[frequency_num]',
                            `<span class="ro-frequency-num">${item.properties.frequency_num}</span>`,
                        )
                        .replace(
                            '[frequency_type_text]',
                            `<span class="ro-frequency-type">${item.properties.frequency_type_text}</span>`,
                        )
                        .replace(
                            '[item_price]',
                            `<span class="ro-price">${ShopifyHelper.displayMoney(itemPrice)}</span>`,
                        );

                    if (item.properties._secondary_discount_required_orders) {
                        let dynamicDiscountPrice = item.original_price;
                        if (BoldCartPropTemplate.cart.is_fixed) {
                            dynamicDiscountPrice = item.original_price / ((100 - item.properties._ro_discount_percentage) * 0.01);
                        }

                        if (item.properties._secondary_discount_percent) {
                            dynamicDiscountPrice = calculateDiscountPercentPrice(item.properties._secondary_discount_percent, dynamicDiscountPrice);
                        }

                        templateObj.secondaryDiscountLineItem = translations.secondary_discount_line_item
                            .replace(
                                '[secondary_discount_required_orders]',
                                `<span class="ro-secondary-discount-req-orders">${item.properties._secondary_discount_required_orders}</span>`,
                            )
                            .replace(
                                '[secondary_discount_price]',
                                `<span class="ro-secondary-disount-price">${ShopifyHelper.displayMoney(dynamicDiscountPrice)}</span>`,
                            );
                    }
                }

                if (typeof item.properties.total_recurrences !== 'undefined' && item.properties.total_recurrences !== '') {
                    templateObj.totalRecurrencesLineItem = translations.total_recurrences_line_item
                        .replace(
                            '[total_recurrences]',
                            `<span class="ro-total-recurrences">${item.properties.total_recurrences}</span>`,
                        );
                }

                return Mustache.render(cartLineItems, templateObj);
            }

            return '';
        });
    }

    addLoading() {
        if (this.settings.loadingEnabled) {
            const { BOLD: { common: { Shopify: { variants } } } } = window;
            const allForms = document.querySelectorAll('form[action*="/cart/add"]:not([class*=no_ro_widget])');
            map(allForms, (form) => {
                const addToCartButtons = form.querySelectorAll('[type="submit"]');
                const fields = {};
                fields.translate = () => (text, render) => {
                    const result = window.BOLD.recurring_orders.language.translations[render(text)];
                    return render(result);
                };
                const loadingTextElem = document.createElement('span');
                loadingTextElem.className = 'bold-ro__widget-loading';
                loadingTextElem.setAttribute('data-widget-loading', '');
                loadingTextElem.setAttribute('style', 'display:none');
                const roWidget = form.querySelector('.ro_widget');
                if (roWidget) {
                    const parentNodeRoWidget = roWidget.parentNode;
                    parentNodeRoWidget.insertBefore(loadingTextElem, roWidget);
                } else if (addToCartButtons.length !== 0) {
                    const parentNodeAddCart = addToCartButtons[0].parentNode;
                    parentNodeAddCart.insertBefore(loadingTextElem, addToCartButtons[0]);
                }

                const loading = form.querySelector(DATA_WIDGET_LOADING);
                if (loading) {
                    this.DOMLoadingElements.push(new DOMHandler(TEMPLATE_WIDGET_LOADING, fields, loading));
                }

                if (typeof form.id.value === 'undefined' || typeof variants[form.id.value] === 'undefined' || !isValidGroup(variants[form.id.value].group_id)) {
                    return;
                }

                for (let btn = 0; btn < addToCartButtons.length; btn += 1) {
                    addToCartButtons[btn].style.visibility = 'hidden';
                    this.startLoading(form);
                }
            });
            this.ee.emit('widget_loading_started');
        }
    }

    startLoading(form) {
        if (this.settings.loadingEnabled) {
            // Hide all buttons. When widget loads it will display the correct button
            const buttons = form.querySelectorAll('[type=submit], [class*="bold-ro__custombutton-"]');
            map(buttons, (btn) => {
                btn.style.visibility = 'hidden';
            });

            const loadingSelector = form.querySelector(DATA_WIDGET_LOADING);
            if (loadingSelector) {
                loadingSelector.style.display = '';
            }
            const widgetSelector = form.querySelector(RO_CLASS_IDENTIFIER);
            if (widgetSelector) {
                widgetSelector.style.display = 'none';
            }
        }
    }

    stopLoading(form = null) {
        if (this.settings.loadingEnabled) {
            if (form === null) {
                const allForms = document.querySelectorAll('form[action*="/cart/add"]');
                map(allForms, (frm) => {
                    const loading = frm.querySelectorAll(DATA_WIDGET_LOADING);
                    if (loading) {
                        map(loading, (ele) => {
                            ele.style.display = 'none';
                        });
                    }
                    const addToCartButtons = frm.querySelectorAll('[type="submit"]');

                    for (let btn = 0; btn < addToCartButtons.length; btn += 1) {
                        addToCartButtons[btn].style.visibility = 'visible';
                        this.ee.emit('cart_button_visible');
                    }
                    const widgetSelector = frm.querySelector(RO_CLASS_IDENTIFIER);
                    if (widgetSelector) {
                        widgetSelector.style.display = '';
                    }
                });
            } else {
                const loading = form.querySelectorAll(DATA_WIDGET_LOADING);
                if (loading) {
                    map(loading, (ele) => {
                        ele.style.display = 'none';
                    });
                }
                const addToCartButtons = form.querySelectorAll('[type="submit"]');

                for (let btn = 0; btn < addToCartButtons.length; btn += 1) {
                    addToCartButtons[btn].style.visibility = 'visible';
                    this.ee.emit('cart_button_visible');
                }
                const widgetSelector = form.querySelector(RO_CLASS_IDENTIFIER);
                if (widgetSelector) {
                    widgetSelector.style.display = '';
                }
            }
        }
    }

    initializeWidget() {
        this.loadWidget(RO_CLASS_IDENTIFIER);
        this.loadWidget(VARIANT_DOM_SELECTOR);
        this.allROProductsLoaded()
            .then(() => {
                BoldCartPropTemplate.render();
                const roProductsWithSubs = filter(this.recurringOrderProducts, (ro) => ro.groupId);
                if (roProductsWithSubs.length > 0) {
                    this.ee.emit('products_loaded', { ro_products: this.recurringOrderProducts });
                } else {
                    this.ee.emit('products_loaded', { ro_products: [] });
                    this.ee.emit('no_products_loaded');
                }
            })
            .catch((e) => {
                console.error('ERROR in allROProductsLoaded promise:', e);
                this.stopLoading();
            });
        this.loadLoginAlert();
        this.addToExisting.render();
        this.bindEvents();
    }

    reboot() {
        map(this.recurringOrderProducts, (prod) => {
            prod.removeAllListeners();
            prod.setSubscriptionState(false);
        });

        // Remove all ro_widgets and custom buttons
        const selectors = document.querySelectorAll(RO_CLASS_IDENTIFIER);
        map(selectors, (ele) => {
            map(ele.childNodes, (childEle) => {
                ele.removeChild(childEle);
            });
        });
        const custBtnSelectors = document.querySelectorAll('[class*="bold-ro__custombutton-"]');
        map(custBtnSelectors, (ele) => {
            ele.parentNode.removeChild(ele);
        });
        const addToExistBtnSelectors = document.querySelectorAll('[data-open-add-to-existing]');
        map(addToExistBtnSelectors, (ele) => {
            ele.parentNode.removeChild(ele);
        });
        this.recurringOrderProducts = [];
        this.productForms = [];
        this.initializeWidget();
    }

    /**
     * Finds all product forms on the page and runs the loadROProduct function on them.
     *  If the form element has a div with the RO_NO_WIDGET_CLASS_NAME class then that form will
     *  be ignored.
     * @param selector
     */
    loadWidget(selector) {
        const elements = document.querySelectorAll(selector);
        const foundElements = [];
        for (let i = 0; i < elements.length; i += 1) {
            if (!elements[i].classList.contains(RO_NO_WIDGET_CLASS_NAME)) {
                foundElements.push(elements[i]);
            }
        }

        if (foundElements.length > this.recurringOrderProducts.length) {
            for (let i = this.recurringOrderProducts.length; i < foundElements.length; i += 1) {
                this.loadROProduct(foundElements[i]);
            }
        }
        if (!this.isDomLoaded) {
            window.setTimeout(this.loadWidget.bind(this, selector), 50);
        }
    }

    /**
     * Adds the product form
     * @param ele
     */
    loadROProduct(ele) {
        const form = ShopifyHelper.findFormFromChild(ele);

        if (this.isValidForm(form)) {
            this.productForms.push(form);
            this.recurringOrderProducts.push(new RecurringOrdersProduct(this, ele, form));
        }
    }

    isValidForm(form) {
        return form && !JSHelper.inArray(this.productForms, form);
    }

    isValidProductId(productId) {
        if (Number.isNaN(productId)) {
            return false;
        }

        if (this.settings && !this.settings.loadOnDuplicateProductForms) {
            return !this.recurringOrderProducts.some((rop) => rop.productId === productId);
        }

        return true;
    }

    unload() {
        this.recurringOrderProducts.forEach((rop) => {
            rop.unload();
        });
    }

    allROProductsLoaded() {
        return Promise.all(map(this.recurringOrderProducts, (roProduct) => roProduct.status));
    }

    static loadCartDoctor(rawCart = {}) {
        const cart = Object.keys(rawCart).length === 0 ? ShopifyHelper.getCartJsObj() : rawCart;
        const cartDoctor = new BoldCartDoctor(cart);
        if (typeof window.BOLD !== 'undefined' && typeof window.BOLD.common !== 'undefined') {
            JSHelper.windowSet('BOLD.common.cartDoctor', cartDoctor, false);
        }
        return cartDoctor;
    }

    productOn(productId, event, fn, context) {
        this.ee.onOut(`BOLD_RECURRING_ORDERS_${productId}_${event}`, fn, context);
    }

    productEmit(productId, event, data) {
        this.ee.emitOut(`BOLD_RECURRING_ORDERS_${productId}_${event}`, data);
    }

    refreshTemplates(type) {
        this.ee.emit(`template_changed_${type}`);
    }

    addPolyfill(polyfill) {
        this.polyfills.push(polyfill);
    }

    loadModal() {
        const modalSelector = document.querySelector('.bold-ro__modal-container');
        if (!modalSelector) {
            const modal = document.createElement('div');
            modal.classList.add('bold-ro__modal-container');
            modal.style.display = 'none';

            const modalContent = document.createElement('div');
            modalContent.classList.add('bold-ro__modal');

            const modalInner = document.createElement('div');
            modalInner.classList.add('bold-ro__modal-inner');

            const closeButton = document.createElement('a');
            closeButton.classList.add('bold-ro__modal-close');
            closeButton.setAttribute('data-bold-modal-close', '');
            closeButton.tabIndex = 0;
            closeButton.textContent = 'X';

            const modalBody = document.createElement('div');
            modalBody.classList.add('bold-ro__modal-content');

            modalInner.appendChild(closeButton);
            modalInner.appendChild(modalBody);
            modalContent.appendChild(modalInner);
            modal.appendChild(modalContent);

            document.body.appendChild(modal);
        }
    }

    loadLoginAlert() {
        const hiderSelector = document.querySelector('.bold-ro__modal-hider');
        if (!hiderSelector) {
            const loginAlert = document.createElement('div');
            loginAlert.classList.add('bold-ro__modal-hider');
            loginAlert.id = 'bold-ro_login-modal';
            loginAlert.innerHTML = `<div class="bold-ro__login-modal-text">
                                  ${window.BOLD.recurring_orders.language.translations.login_alert}
                                </div>
                                <button id="bold-ro__login-btn" class="bold-ro__btn btn button">${window.BOLD.recurring_orders.language.translations.login_button}</button>`;
            document.querySelector('.bold-ro__modal-container').appendChild(loginAlert);
            const loginBtn = document.getElementById('bold-ro__login-btn');
            if (loginBtn) {
                loginBtn.addEventListener('click', () => {
                    this.ee.emit('ro_required_login', { ro_product: this });
                });
            }
            const options = {
                modal: '.bold-ro__modal',
                modalInner: '.bold-ro__modal-inner',
                modalContent: '.bold-ro__modal-content',
                open: '[data-modal-open], [data-bold-modal-open]',
                close: '[data-modal-close], [data-bold-modal-close]',
                page: 'body',
                loadClass: 'bold-ro__vanilla-modal',
                class: 'bold-ro__modal-visible',
                clickOutside: false,
                closeKeys: [27],
                transitions: true,
                onBeforeOpen: () => {
                    this.displayModal(true);
                },
                onBeforeClose: null,
                onOpen: null,
                onClose: () => {
                    this.displayModal(false);
                },
            };
            this.modal = new VanillaModal(options);
        }
    }

    displayModal(isVisible) {
        const loginModal = document.querySelector('.bold-ro__modal-container');
        if (loginModal) {
            if (isVisible) {
                loginModal.removeAttribute('style');
            } else {
                loginModal.setAttribute('style', 'display:none;');
            }
        }
    }

    login() {
        const pageUrlSelector = document.querySelector('meta[itemprop="url"]');
        if (pageUrlSelector) {
            const pageUrl = pageUrlSelector.getAttribute('content');
            document.cookie = 'product_page_cookie=1; path=/';
            document.cookie = `product_page_url=${pageUrl}; path=/`;
        }
        document.location = `${window.BOLD.common.Shopify.shop.url}/account/login`;
    }

    removeSingleProductRecurringItems() {
        if (JSHelper.inArray(this.settings.clearCartBlacklist, this.page, true) || window.location.pathname === '/challenge') {
            return;
        }

        fetch('/cart.json', { credentials: 'same-origin' }).then((resp) => resp.json())
            .then((cartJson) => {
                let singleProductRecurringItemFound = false;
                cartJson.items.forEach((item) => {
                    if (item.properties && item.properties._ro_single_product_recurring_item) {
                        singleProductRecurringItemFound = true;
                    }
                });

                if (singleProductRecurringItemFound) {
                    fetch('/cart/clear.js', { method: 'POST', credentials: 'same-origin' })
                        .then(() => {
                            if (window.location.pathname.indexOf('/cart') !== -1) {
                                window.location.reload();
                            }
                        });
                }
            });
    }

    debug(debugSettings) {
        window.BOLD.common.debug = window.BOLD.common.debug || {};
        if (typeof debugSettings === 'undefined') {
            window.BOLD.common.debug.logEvents = true;
        } else if (debugSettings === true) {
            window.BOLD.common.debug.logEvents = true;
            window.BOLD.common.debug.logEventData = true;
        } else {
            map(debugSettings, (setting) => {
                window.BOLD.common.debug[setting] = debugSettings[setting];
            });
        }
    }
}

export default RecurringOrdersBootstrap;
