const customFrequencyInterval = `
<div class="bold-ro__order-interval-container" {{^subscription_checked}} style="display:none;" {{/subscription_checked}}>
    <label class="bold-ro__custom-order-interval-lbl">{{#translate}}deliver_every{{/translate}}</label>
    {{#frequency_num}}
    <input type="number" id="frequency_num" name="frequency_num" class="bold-ro__frequency-num" value="1" min="1" max="{{frequency_num_id}}">
    {{/frequency_num}}
    <select class="bold-ro__frequency-type bold-ro__order-interval">
        {{#frequency_type}}
    <option data-frequency-type="{{frequency_type_id}}" data-frequency-type-text="{{#translate}}{{frequency_type_translation}}{{/translate}}" value="{{frequency_type_id}}">{{#translate}}{{frequency_type_translation}}{{/translate}}</option>
        {{/frequency_type}}
    </select>
    {{#shipping_schedule_hover_enable}}<a href="javascript:;" class="bold-ro__shipping-tooltip">{{#translate}}shipping_schedule{{/translate}}</a>{{/shipping_schedule_hover_enable}}
</div>
<input name="frequency_type_text" class="bold-ro__frequency-type-text" type="hidden" value="">
`;

export default customFrequencyInterval;
