const standardFrequencyInterval = `
<div class="bold-ro__order-interval-container" {{^subscription_checked}} style="display:none;" {{/subscription_checked}}>
    <span id="bold-ro__order-interval-label" class="bold-ro__order-interval-lbl">{{#translate}}deliver_every{{/translate}}</span>            
    <select class="bold-ro__frequency-num" name="frequency_num" aria-labelledby="bold-ro__order-interval-label">
        {{#frequency_num}}
            <option data-frequency-num="{{frequency_num_id}}" value="{{frequency_num_id}}">{{frequency_num_id}}</option>
        {{/frequency_num}}
    </select>
    <select name="frequency_type" class="bold-ro__frequency-type bold-ro__order-interval" aria-labelledby="bold-ro__order-interval-label">
        {{#frequency_type}}
            <option data-frequency-type="{{frequency_type_id}}" data-frequency-type-text="{{#translate}}{{frequency_type_translation}}{{/translate}}" value="{{frequency_type_id}}">{{#translate}}{{frequency_type_translation}}{{/translate}}</option>
        {{/frequency_type}}
    </select>
    {{#shipping_schedule_hover_enable}}
<a href="javascript:;" class="bold-ro__shipping-tooltip">{{#translate}}shipping_schedule{{/translate}}</a>
    {{/shipping_schedule_hover_enable}}
</div>        
<input name="frequency_type_text" class="bold-ro__frequency-type-text" type="hidden" value="">
`;

export default standardFrequencyInterval;
