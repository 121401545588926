import {
    remove, map, get, merge,
} from 'lodash';
import Tooltip from 'tooltip.js';
import {
    JSHelper,
    DOMHelper,
    ShopifyHelper,
} from '@bold-commerce/bold-common-js';
import * as propertyConst from '../constants/properties';
import {
    TEMPLATE_SUBSCRIPTION_STANDARD,
    TEMPLATE_SUBSCRIPTION_BOX,
    SUBSCRIPTION_CONVERTIBLE_NUM,
    SUBSCRIPTION_BOX_NUM,
    CUSTOMER,
    CLASS_SELECTOR_BOLD_ACTIVE,
    CLASS_SELECTOR_RECURRING_LABEL,
    CLASS_NAME_BOLD_ACTIVE,
    NAME_RECURRING_RADIO_SELECTION,
    ONE_TIME_PRODUCT,
    SINGLE_PRODUCT_RECURRING,
    MIXED_PRODUCT_RECURRING,
    BOX_PRODUCT_RECURRING,
    CLASS_SELECTOR_CUSTOM_BUTTON,
    CLASS_NAME_CUSTOM_BUTTON,
    NAME_IS_PREPAID,
    NAME_IS_PREPAID_START_DATE,
    DATA_PREPAID_ALWAYS_EXPIRED,
    VARIANT_SELECTOR_TIMEOUT,
    CLASS_SELECTOR_FREQUENCY_NUM,
    CLASS_SELECTOR_FREQUENCY_TYPE,
    CLASS_SELECTOR_FREQUENCY_TYPE_TEXT,
    CLASS_SELECTOR_PREPAID_CHECKBOX,
    NAME_PREPAID_LENGTH_ID,
    NAME_TOTAL_RECURRENCES,
    HTML_TOOLTIP_TEMPLATE,
    CLASS_SELECTOR_PREPAID_CHECKBOX_LABEL,
    CLASS_SELECTOR_SUBSCRIPTION_LENGTH,
    CLASS_SELECTOR_PREPAID_PERCENT,
    CLASS_SELECTOR_PREPAID_DISCOUNT_AMOUNT,
    CLASS_SELECTOR_PREPAID_DISCOUNT_PRICE,
    CLASS_SELECTOR_PREPAID_TOTAL,
    CLASS_SELECTOR_PREPAID_DISCOUNT_TEXT,
    NAME_IS_PREPAID_LENGTH_ID,
    NAME_IS_GIFT,
    NAME_PREPAID_START_DATE,
    VARIANT_DOM_SELECTOR,
    CLASS_SELECTOR_PREPAID_LENGTH_SELECT,
    CLASS_SELECTOR_PREPAID_GIFT_CONTAINER,
    CLASS_SELECTOR_PREPAID_LENGTH,
    CLASS_SELECTOR_LIMITED_LENGTH_SELECT,
    CLASS_SELECTOR_PREPAID_TOTAL_RECURRENCES,
    CLASS_SELECTOR_PREPAID_TOTAL_CONTAINER,
    CLASS_SELECTOR_LIMITED_LENGTH_TOTAL_RECURRENCES,
    CLASS_SELECTOR_PREPAID_START_DATE_OPTION_CONTAINER,
    CLASS_SELECTOR_PREPAID_START_DATE,
} from '../constants/index';
import {
    commonMergeFieldFunctions,
    googleAnalyticsGetParamString,
    getCookie,
    isValidGroup,
    isRecurringCartMode,
    generateConvertibleLineItemProperty,
    calculateDiscountPercentAmount,
    calculateDiscountPercentPrice,
} from '../AppClasses/HelperFunctions';
import {
    isCashierEnabled,
} from '../helpers/cashierHelpers';
import DOMHandler from '../AppClasses/DOMHandler';

class RecurringOrdersProduct {
    constructor(app, element, form = null, productId = null) {
        const { BOLD: { common: { Shopify: { variants } } } } = window;
        this.app = app;
        this.element = this.getElement(element, form);
        this.form = this.getForm(form);
        this.productId = this.getProductId(productId);
        this.variantId = this.form.elements.id.value;
        this.subscription_checked = false;
        this.ee = app.ee;
        this.bindEvents();
        this.setupEventListeners();
        this.addCustomButtons();
        this.createExistingOrdersButton();

        if (this.variantId !== ''
            && typeof variants[this.variantId] !== 'undefined'
            && isValidGroup(variants[this.variantId].group_id)
            && this.productId) {
            this.price = variants[this.variantId].price;
            this.groupId = variants[this.variantId].group_id;
            this.detailTooltips = [];
            this.shippingTooltips = [];
            this.status = this.getWidget()
                .then((resp) => {
                    this.init(resp, this);
                })
                .catch((e) => {
                    // TODO: Refactor into throw new Error()
                    console.error(`Unable to receive response from ${window.BOLD.recurring_orders.path}api_public/group/${this.groupId}?shop_url=${ShopifyHelper.getShopUrl()} Error:`, e);
                    this.app.stopLoading(this.form);
                });
        } else {
            remove(this.app.productForms, (n) => n === this.form);
            this.app.stopLoading(this.form);
        }
    }

    setupEventListeners() {
        this.ee.on('variant_changed', this.reload, this);
        this.ee.on('no_widget_loaded', this.removeWidget, this);
        this.ee.on('shipping_plan_changed', this.updateShippingTooltips, this);
        this.ee.on('template_initialized', this.updateShippingTooltips, this);
        this.ee.on('template_initialized', this.updateDetailTooltips, this);
        this.ee.on('template_initialized', this.removeLoading, this);
        this.ee.on('template_refreshed', this.updateShippingTooltips, this);
        this.ee.on('template_refreshed', this.updateDetailTooltips, this);
        this.ee.on('template_refreshed', this.removeLoading, this);
        this.ee.on('mixed_product_recurring', this.mixedProductRecurring, this);
        this.ee.on('one_time_product_selected', this.oneTimeProductSelected, this);
        this.ee.on('single_product_recurring', this.singleProductRecurring, this);
        this.ee.on('subscription_box_recurring', this.subscriptionBoxRecurring, this);
        this.ee.on('order_interval_changed', this.orderIntervalChanged, this);
        this.ee.on('subscription_length_changed', this.subscriptionLengthChanged, this);
        this.ee.on('single_product_custom_button_clicked', this.singleProductCustomButtonClicked, this);
        this.ee.on('prepaid_changed', this.prepaidChanged, this);
        this.ee.on('prepaid_start_date_changed', this.prepaidStartDateChanged, this);
    }

    removeLoading() {
        this.app.stopLoading();
    }

    // Adds a clone of all submit buttons
    addCustomButtons() {
        const { BOLD: { recurring_orders: { settings } } } = window;
        const buttons = this.form.querySelectorAll('button[type="submit"]:not(.bold_hidden),.addtocart:not(.bold_hidden)');
        map(buttons, (button) => {
            const customButtonExists = this.form.querySelector(`${CLASS_SELECTOR_CUSTOM_BUTTON}${this.productId}`);
            if (!customButtonExists) {
                const customButton = document.createElement('button');
                let btnClasses = `${settings.loadCustomButtonClasses} ${CLASS_NAME_CUSTOM_BUTTON}${this.productId} `;
                if (settings.useAddToCartClasses) {
                    btnClasses = btnClasses.concat(customButton.classList.add(button.className));
                }
                customButton.className = btnClasses;
                customButton.classList.remove('addtocart');
                customButton.appendChild(document.createTextNode(button.innerText));
                customButton.setAttribute('style', 'display: none;');
                button.classList.add('cartbutton');
                button.parentNode.insertBefore(customButton, button);
                const customButtonElement = document.querySelector(`.${CLASS_NAME_CUSTOM_BUTTON}${this.productId}`);
                if (customButtonElement) {
                    window.BOLD.common.eventEmitter.emit('BOLD_COMMON_clone_addtocart_button', customButtonElement);
                }
            }
        });

        map(this.form.querySelectorAll(`${CLASS_SELECTOR_CUSTOM_BUTTON}${this.productId}`), (button) => {
            button.addEventListener('click', (evt) => {
                evt.preventDefault();
                this.ee.emit('single_product_custom_button_clicked', { ro_product: this, recurring_orders_product: this, form: this.form });
            }, false);
        });
    }

    /**
     * When in Recurring Cart mode all products need an Add to Existing Orders button.
     * For Mixed and Single Product cart modes this is handled in the init function because the requirements
     *   for the Add to Existing Orders varies based on the Subscription Group type.
     */
    createExistingOrdersButton() {
        if (isRecurringCartMode()) {
            this.app.addToExisting.addExistingOrdersButton(this.form, this.productId);
        }
    }

    getWidget() {
        const query = {};
        if (this.app.settings.previewEnabled) {
            query.access_all = true;
        }
        return this.app.api.retrieve({ endpoint: `group/${this.groupId}`, query });
    }

    setSubscriptionState(state) {
        if (typeof this.app.settings.resetSubscriptionStateOnReboot !== 'undefined'
            && this.app.settings.resetSubscriptionStateOnReboot) {
            if (typeof window.BOLD.recurring_orders.cached_group[this.groupId] !== 'undefined'
                && window.BOLD.recurring_orders.cached_group[this.groupId]) {
                window.BOLD.recurring_orders.cached_group[this.groupId].subscription_checked = state;
            }
        } else {
            this.subscription_checked = state;
            // Need to set the subscription checked state for all cached groups
            map(window.BOLD.recurring_orders.cached_group, (cachedGroup) => {
                cachedGroup.subscription_checked = state;
            });
        }
    }

    setVariantId(variantId) {
        this.variantId = variantId;
    }

    setProductId(productId) {
        this.productId = productId;
    }

    setGroupId(groupId) {
        this.groupId = groupId;
    }

    expandFrequency(groupData) {
        if (!groupData.frequency_too_large && !groupData.fixed_interval) {
            const frequencyNums = [];
            for (let i = 0; i < groupData.frequency_num; i += 1) {
                frequencyNums.push({ frequency_num_id: i + 1 });
            }
            groupData.frequency_num = frequencyNums;
        } else {
            const frequencyNums = [{ frequency_num_id: groupData.frequency_num }];
            groupData.frequency_num = frequencyNums;
        }

        return groupData;
    }

    init(groupData, ctrl) {
        const { BOLD: { recurring_orders, common: { Shopify } } } = window;
        this.app.addToExisting.addExistingOrdersButton(ctrl.form, ctrl.productId, groupData);
        const { productId } = ctrl;
        const currentForm = ctrl.form;
        let resp = { ...groupData };
        if (typeof groupData.frequency_num !== 'object') {
            resp = this.expandFrequency(groupData);
        }
        if (resp.settings !== null) {
            recurring_orders.setSettings(resp.settings, false);
            ctrl.app.ee.emit('settings_loaded');
        }
        if (typeof resp.recurring_group !== 'undefined' && !groupData.recurring_mode.cart) {
            // ctrl.variantId = form.id.value;
            resp.product_id = productId;
            resp.variant_id = ctrl.variantId;
            resp.price = Shopify.variants[resp.variant_id].price;

            const usingCashier = resp.is_cashier_linked && isCashierEnabled();
            const otherParams = usingCashier
                ? { shop: resp.shop_url }
                : { shop_url: resp.shop_url };

            googleAnalyticsGetParamString(otherParams)
                .then((queryString) => {
                    if (usingCashier) {
                        ctrl.form_action = `/apps/checkout/begin-checkout${queryString}`;
                    } else if (window.BOLD.recurring_orders.app.cartWidget.isCheckoutProxyPage) {
                        ctrl.form_action = `https://${window.BOLD.common.Shopify.shop.domain}/tools/checkout/subscriptions/recurring_product?shop_url=${window.BOLD.common.Shopify.shop.permanent_domain}`;
                    } else {
                        ctrl.form_action = `${resp.s_shop_url.replace('ro.boldapps.net', 'recurringcheckout.com')}checkout/recurring_product${queryString}`;
                    }

                    ctrl.form_action_sub_box = `https://${
                        Shopify.shop.domain
                    }/tools/checkout/manage_subscription_box/select_products_checkout/${
                        ctrl.productId}/${ctrl.variantId}${queryString}`;

                    ctrl.ee.emit('before_widget_loaded', {
                        ro_product_json: resp,
                        product_handle: Shopify.handles[ctrl.productId],
                        recurring_orders_product: this,
                    });

                    recurring_orders.cached_group[ctrl.groupId] = resp;

                    resp = this.addTemplateFunctions(resp, currentForm);
                    this.initializeTemplate(resp);
                    const widgetLoadedData = {
                        product_id: productId,
                        variant_id: ctrl.variantId,
                        ro_product: ctrl,
                        recurring_orders_product: ctrl,
                        form: currentForm,
                        product_json: resp,
                    };

                    ctrl.ee.emit('widget_loaded', widgetLoadedData);
                    this.widgetEvents(widgetLoadedData);

                    if (resp.is_prepaid_only) {
                        const prepaidLengthSelector = currentForm.querySelector('.bold-ro__subscription-length');
                        this.ee.emit('subscription_length_changed', {
                            ro_product: this, recurring_orders_product: this, element: prepaidLengthSelector, form: currentForm,
                        });
                    }

                    const isPrepaidSelector = currentForm.querySelector(NAME_IS_PREPAID);
                    const isPrepaidStartDateSelector = currentForm.querySelector(NAME_IS_PREPAID_START_DATE);
                    // TODO Simplify this initialization of the widget
                    if (resp.subscription_type === SUBSCRIPTION_BOX_NUM) {
                        this.ee.emit('subscription_box_recurring', { ro_product: this, recurring_orders_product: this, form: currentForm });
                    } else if (ctrl.subscription_checked || resp.subscription_only || groupData.subscription_checked || resp.is_subscription_default_on_widget) {
                        // TODO Use resp variable instead of function
                        if (this.isSingleProductRecurring() || resp.is_prepaid_only) {
                            this.ee.emit('single_product_recurring', { ro_product: this, recurring_orders_product: this, form: currentForm });
                        } else {
                            this.ee.emit('mixed_product_recurring', { ro_product: this, recurring_orders_product: this, form: currentForm });
                        }
                        if (isPrepaidSelector) {
                            this.ee.emit('prepaid_changed', {
                                ro_product: this, recurring_orders_product: this, checked: isPrepaidSelector.checked, form: currentForm,
                            });
                        }
                        if (isPrepaidStartDateSelector) {
                            this.ee.emit('prepaid_start_date_changed', {
                                ro_product: this, recurring_orders_product: this, checked: isPrepaidStartDateSelector.checked, form: currentForm,
                            });
                        }
                    }
                });
        } else {
            ctrl.ee.emit('no_widget_loaded', {
                product_id: productId,
                variant_id: ctrl.variantId,
                form: currentForm,
                recurring_orders_product: this,
            });
        }
    }

    // Bind event listener to Change event on single option selector
    bindEvents() {
        const selectors = this.form.querySelectorAll('.single-option-selector,[name^="id"],[class*="single-option"]');
        if (selectors.length > 0) {
            map(selectors, (selector) => {
                selector.addEventListener('change', (e) => {
                    const currentForm = e.target.form;
                    const variantId = this.form.id.value;
                    const timeToWait = (this.variantId === variantId) ? VARIANT_SELECTOR_TIMEOUT : 0;
                    // The timeout is needed incase our eventListener is executed before the theme changes the
                    //   name=id value to the correct variant id.
                    setTimeout(() => {
                        this.ee.emit('variant_changed', { ro_product: this, recurring_orders_product: this, form: currentForm });
                    }, timeToWait);
                });
            });
        }
    }

    widgetEvents(data) {
        let formElement;
        let productId;
        if (typeof data.data !== 'undefined') {
            formElement = data.data.form;
            productId = data.data.product_id;
        } else {
            formElement = data.form;
            productId = data.product_id;
        }
        const buttons = formElement.querySelectorAll(CLASS_SELECTOR_RECURRING_LABEL);

        // If there is no form or no productId for the RecurringOrdersProduct then don't
        //  add the event listener
        if (formElement === undefined || productId === undefined) {
            return;
        }
        map(buttons, (button) => {
            button.addEventListener('click', (evt) => {
                const currentForm = formElement;
                // Removes Selection from Previous Radio Button
                const previousActive = currentForm.querySelectorAll(CLASS_SELECTOR_BOLD_ACTIVE);
                for (let j = 0; j < previousActive.length; j += 1) {
                    previousActive[j].classList.remove(CLASS_NAME_BOLD_ACTIVE);
                }
                const element = button.querySelector(NAME_RECURRING_RADIO_SELECTION);
                element.checked = true;
                evt.target.parentElement.closest('div').classList.add(CLASS_NAME_BOLD_ACTIVE);
                const isPrepaidSelector = currentForm.querySelector(NAME_IS_PREPAID);
                const isPrepaidStartDateSelector = currentForm.querySelector(NAME_IS_PREPAID_START_DATE);
                switch (parseInt(element.value, 10)) {
                case ONE_TIME_PRODUCT:
                    // Purchase Product only/Non-recurring
                    this.ee.emit('one_time_product_selected', { ro_product: this, recurring_orders_product: this, form: currentForm });
                    break;
                case SINGLE_PRODUCT_RECURRING:
                    // Single Product Recurring
                    this.ee.emit('single_product_recurring', { ro_product: this, recurring_orders_product: this, form: currentForm });
                    if (isPrepaidSelector) {
                        this.ee.emit('prepaid_changed', {
                            ro_product: this, recurring_orders_product: this, checked: isPrepaidSelector.checked, form: currentForm,
                        });
                    }
                    if (isPrepaidStartDateSelector) {
                        this.ee.emit('prepaid_start_date_changed', {
                            ro_product: this, recurring_orders_product: this, checked: isPrepaidStartDateSelector.checked, form: currentForm,
                        });
                    }
                    break;

                case MIXED_PRODUCT_RECURRING:
                    // Mixed Recurring
                    this.ee.emit('mixed_product_recurring', { ro_product: this, recurring_orders_product: this, form: currentForm });
                    if (isPrepaidSelector) {
                        this.ee.emit('prepaid_changed', {
                            ro_product: this, recurring_orders_product: this, checked: isPrepaidSelector.checked, form: currentForm,
                        });
                    }
                    if (isPrepaidStartDateSelector) {
                        this.ee.emit('prepaid_start_date_changed', {
                            ro_product: this, recurring_orders_product: this, checked: isPrepaidStartDateSelector.checked, form: currentForm,
                        });
                    }
                    break;

                case BOX_PRODUCT_RECURRING:
                    // Subscription Box Recurring
                    this.ee.emit('subscription_box_recurring', { ro_product: this, recurring_orders_product: this, form: currentForm });
                    break;
                default:
                    break;
                }
            });
        });
        // Event Listener for order-interval change
        const orderInterval = formElement.querySelector('.bold-ro__order-interval');
        if (orderInterval) {
            orderInterval.addEventListener('change', () => {
                this.ee.emit('order_interval_changed', { ro_product: this, recurring_orders_product: this, form: formElement });
            }, false);
        }

        map(formElement.querySelectorAll('.bold-ro__billing-plan-select,.bold-ro__frequency-num'), (select) => {
            select.addEventListener('change', () => {
                this.ee.emit('shipping_plan_changed', { ro_product: this, recurring_orders_product: this });
            });
        });

        map(formElement.querySelectorAll('.bold-ro__subscription-length'), (select) => {
            select.addEventListener('change', (e) => {
                const targetForm = e.target.form;
                this.ee.emit('subscription_length_changed', {
                    ro_product: this, recurring_orders_product: this, element: select, form: targetForm,
                });
            });
        });

        map(formElement.querySelectorAll('[name="is_prepaid"]'), (select) => {
            select.addEventListener('change', (e) => {
                const targetForm = e.target.form;
                this.ee.emit('prepaid_changed', {
                    ro_product: this, recurring_orders_product: this, checked: select.checked, form: targetForm,
                });
            });
        });

        map(formElement.querySelectorAll('[name="prepaid_start_date_checkbox"]'), (select) => {
            select.addEventListener('change', (e) => {
                const targetForm = e.target.form;
                this.ee.emit('prepaid_start_date_changed', {
                    ro_product: this, recurring_orders_product: this, checked: select.checked, form: targetForm,
                });
            });
        });

        const frequencyNum = formElement.querySelector('input.bold-ro__frequency-num');
        if (frequencyNum) {
            frequencyNum.onchange = this.validateNumber;
        }
        const prepaidStartDate = formElement.querySelector('input.bold-ro__prepaid-start-date');
        if (prepaidStartDate) {
            prepaidStartDate.onblur = this.validateStartDate;
        }

        this.ee.emit('widget_events_loaded', { ro_product: this, recurring_orders_product: this, form: formElement });
    }

    showWidgetElements(form) {
        const limitedSubscription = form.querySelector('.bold-ro__limited-length-container');
        if (limitedSubscription) {
            limitedSubscription.removeAttribute('style');
        }
        const billingPlan = form.querySelector('.bold-ro__billing-plan-select');
        if (billingPlan) {
            billingPlan.setAttribute('name', 'properties[_ro_billing_plan]');
            billingPlan.removeAttribute('style');
            form.querySelector('.bold-ro__billing-plan-text').removeAttribute('style');
        }
        const orderInterval = form.querySelector('.bold-ro__order-interval-container');
        if (orderInterval) {
            orderInterval.removeAttribute('style');
        }
        const shippingTooltip = form.querySelector('.bold-ro__shipping-tooltip');
        if (shippingTooltip) {
            shippingTooltip.removeAttribute('style');
        }
        const fixedInterval = form.querySelector('.bold-ro__fixed-frequency-text');
        if (fixedInterval) {
            fixedInterval.removeAttribute('style');
        }
        const prepaid = form.querySelector('.bold-ro__prepaid-container');
        if (prepaid) {
            prepaid.removeAttribute('style');
        }
    }

    hideWidgetElements(form) {
        const orderInterval = form.querySelector('.bold-ro__order-interval-container');
        if (orderInterval) {
            orderInterval.setAttribute('style', 'display:none');
        }
        const limitedSubscription = form.querySelector('.bold-ro__limited-length-container');
        if (limitedSubscription) {
            limitedSubscription.setAttribute('style', 'display:none');
            limitedSubscription.querySelector('.bold-ro__limited-length-select').removeAttribute('name');
        }
        const billingPlan = form.querySelector('.bold-ro__billing-plan-select');
        if (billingPlan) {
            billingPlan.setAttribute('style', 'display:none');
            billingPlan.removeAttribute('name');
            form.querySelector('.bold-ro__billing-plan-text').setAttribute('style', 'display:none');
        }
        const shippingTooltip = form.querySelector('.bold-ro__shipping-tooltip');
        if (shippingTooltip) {
            shippingTooltip.setAttribute('style', 'display:none');
        }
        const fixedInterval = form.querySelector('.bold-ro__fixed-frequency-text');
        if (fixedInterval) {
            fixedInterval.setAttribute('style', 'display:none');
        }
        const prepaid = form.querySelector('.bold-ro__prepaid-container');
        if (prepaid) {
            prepaid.setAttribute('style', 'display:none');
        }
    }

    /**
     * Ensures prepaid and limited subscription length drop downs are set to the same value
     *  when toggling prepaid on an off.
     * @param resp - Either the limited subscription length or the prepaid selector element
     */
    subscriptionLengthChanged(resp) {
        const { data: { element, form } } = resp;
        const { BOLD: { common: { Shopify: { variants } } } } = window;
        const variantId = form.querySelector(VARIANT_DOM_SELECTOR).value;
        const groupId = variants[variantId].group_id;
        const price = (variants[variantId] !== undefined) ? variants[variantId].price : null;
        const groupData = window.BOLD.recurring_orders.cached_group[groupId];
        const prepaidCheckbox = form.querySelector(NAME_IS_PREPAID);
        const prepaidCheckboxLabel = form.querySelector(CLASS_SELECTOR_PREPAID_CHECKBOX_LABEL);
        const prepaidSelect = form.querySelector(NAME_IS_PREPAID_LENGTH_ID);
        const prepaidGift = form.querySelector(NAME_IS_GIFT);
        const prepaidSelectors = [prepaidCheckbox, prepaidGift, prepaidSelect];

        if (groupData.prepaid && prepaidSelect && groupId) {
            const index = element.selectedIndex;
            const option = element.options[index];
            // if value of selected option is not blank (no limit) option, change limited length and prepaid drop downs to same index
            map(form.querySelectorAll(CLASS_SELECTOR_SUBSCRIPTION_LENGTH), (ele) => {
                if (option.value !== '') {
                    ele.selectedIndex = index;
                    if (this.isPrepaidChecked(form)) {
                        this.toggleDisabledAttribute(prepaidSelectors, false);
                    } else {
                        this.toggleDisabledAttribute([prepaidCheckbox], false);
                    }
                    if (prepaidCheckboxLabel) {
                        prepaidCheckboxLabel.removeAttribute('style');
                    }
                } else {
                    this.toggleDisabledAttribute(prepaidSelectors, true);
                    if (prepaidCheckboxLabel) {
                        prepaidCheckboxLabel.setAttribute('style', 'text-decoration:line-through');
                    }
                }
            });
            // Trigger Prepaid price update
            if (option.value !== '') {
                const prepaidPercentLabel = form.querySelector(CLASS_SELECTOR_PREPAID_PERCENT);
                const prepaidAmountLabel = form.querySelector(CLASS_SELECTOR_PREPAID_DISCOUNT_AMOUNT);
                const prepaidPriceLabel = form.querySelector(CLASS_SELECTOR_PREPAID_DISCOUNT_PRICE);
                const prepaidPriceTotalElement = form.querySelector(CLASS_SELECTOR_PREPAID_TOTAL);
                const prepaidDiscountPercent = groupData.prepaid.lengths[index].discount;
                const prepaidLength = parseInt(option.getAttribute('data-length'), 10);
                const prepaidDiscountTextElement = form.querySelector(CLASS_SELECTOR_PREPAID_DISCOUNT_TEXT);
                this.updatePrepaidPrice(prepaidPercentLabel, prepaidAmountLabel, prepaidPriceLabel, prepaidPriceTotalElement, prepaidDiscountPercent, prepaidLength, groupData, prepaidDiscountTextElement, price);
            }
        }
    }

    updatePrepaidPrice(percentLabel, amountLabel, priceLabel, priceTotalElement, discountPercent, prepaidLength, groupData, prepaidDiscountTextElement, price) {
        const discountAmount = this.calculateDiscountAmount(price, discountPercent);
        const discountPrice = Math.round(this.calculateDiscountPrice(price, discountAmount), 0);
        const totalPrice = this.calculateTotalPrepaid(discountPrice, prepaidLength);

        this.setElementTextContent(percentLabel, `${discountPercent}%`);
        this.setElementInnerHTML(amountLabel, ShopifyHelper.displayMoney(discountAmount, groupData.money_format));
        this.setElementInnerHTML(priceLabel, ShopifyHelper.displayMoney(discountPrice, groupData.money_format));
        this.setElementInnerHTML(priceTotalElement, ShopifyHelper.displayMoney(totalPrice, groupData.money_format));
        if (discountPercent <= 0) {
            prepaidDiscountTextElement.style.display = 'none';
        } else {
            prepaidDiscountTextElement.style.display = '';
        }
    }

    calculateTotalPrepaid(prepaidAmount, quantity) {
        return prepaidAmount * quantity;
    }

    calculateDiscountPrice(price, prepaidDiscountAmount) {
        return price - prepaidDiscountAmount;
    }

    calculateDiscountAmount(price, prepaidDiscountPercent) {
        return price * (prepaidDiscountPercent / 100);
    }

    setElementInnerHTML(element, html) {
        if (element) {
            element.innerHTML = html;
        }
    }

    setElementTextContent(element, text) {
        if (element) {
            element.textContent = text;
        }
    }

    isPrepaidChecked(form) {
        let result = false;
        const prepaidCheckbox = form.querySelector('[name="is_prepaid"]');
        if (prepaidCheckbox && prepaidCheckbox.checked) {
            result = true;
        }
        return result;
    }

    /**
     * Disables or enables Prepaid functionality if the No limit option is selected from the
     *  limited subscription length drop down.
     * @param selectors - list of elements to disable or remove disable
     * @param disable - True indicates to disable the element
     */
    toggleDisabledAttribute(selectors, disable) {
        map(selectors, (ele) => {
            if (ele) {
                if (disable === false) {
                    ele.removeAttribute('disabled');
                } else {
                    ele.setAttribute('disabled', true);
                }
            }
        });
    }

    /**
     * Displays or hides the list of elements passed in with the selectors parameter
     * @param selectors
     * @param visible - true indicates that the elements will be set to show
     */
    toggleVisibility(selectors, visible) {
        map(selectors, (ele) => {
            if (ele) {
                if (visible === true) {
                    ele.removeAttribute('style');
                } else {
                    ele.setAttribute('style', 'display:none');
                }
            }
        });
    }

    prepaidStartDateChanged(resp) {
        const { data: { checked, form } } = resp;
        const currentForm = form;
        if (currentForm !== this.form) {
            return;
        }
        const prepaidStartDate = currentForm.querySelector(CLASS_SELECTOR_PREPAID_START_DATE);
        const prepaidStartDateInput = currentForm.querySelector('.bold-ro__prepaid-start-date');
        if (checked) {
            this.toggleVisibility([prepaidStartDate], true);
            prepaidStartDateInput.setAttribute('name', '_prepaid_start_date');
        } else {
            this.toggleVisibility([prepaidStartDate], false);
            prepaidStartDateInput.removeAttribute('name');
        }
    }

    prepaidChanged(resp) {
        const { data: { checked, form } } = resp;
        const { BOLD: { common: { Shopify: { variants } } } } = window;
        const currentForm = form;
        if (currentForm !== this.form) {
            return;
        }
        const variantId = form.querySelector(VARIANT_DOM_SELECTOR).value;
        const groupId = variants[variantId].group_id;
        const groupData = window.BOLD.recurring_orders.cached_group[groupId];
        const prepaidCheckbox = currentForm.querySelector(NAME_IS_PREPAID);
        const prepaidAlwaysExpired = currentForm.querySelector(DATA_PREPAID_ALWAYS_EXPIRED);
        const prepaidGiftContainer = currentForm.querySelector(CLASS_SELECTOR_PREPAID_GIFT_CONTAINER);
        const prepaidGiftCheckbox = currentForm.querySelector(NAME_IS_GIFT);
        const prepaidLengthElement = currentForm.querySelector(CLASS_SELECTOR_PREPAID_LENGTH_SELECT);
        const prepaidLengthContainer = currentForm.querySelector(CLASS_SELECTOR_PREPAID_LENGTH);
        const limitedLengthElement = currentForm.querySelector(CLASS_SELECTOR_LIMITED_LENGTH_SELECT);
        const prepaidTotalRecurrences = currentForm.querySelector(CLASS_SELECTOR_PREPAID_TOTAL_RECURRENCES);
        const prepaidTotalPrice = currentForm.querySelector(CLASS_SELECTOR_PREPAID_TOTAL_CONTAINER);
        const prepaidStartDateCheckboxContainer = currentForm.querySelector(CLASS_SELECTOR_PREPAID_START_DATE_OPTION_CONTAINER);
        const prepaidStartDate = currentForm.querySelector(NAME_PREPAID_START_DATE);
        if (checked || groupData.is_prepaid_only) {
            this.toggleVisibility([prepaidGiftContainer, prepaidLengthContainer, prepaidTotalPrice, prepaidStartDateCheckboxContainer], true);
            this.toggleVisibility([limitedLengthElement], false);
            const selectors = [prepaidCheckbox, prepaidGiftCheckbox, prepaidLengthElement, prepaidTotalRecurrences, prepaidAlwaysExpired, prepaidStartDate];
            this.toggleDisabledAttribute(selectors, false);
            // change form action
            this.ee.emit('single_product_recurring', { ro_product: this, recurring_orders_product: this, form: currentForm });
        } else {
            this.toggleVisibility([prepaidGiftContainer, prepaidLengthContainer, prepaidTotalPrice, prepaidStartDateCheckboxContainer], false);
            this.toggleVisibility([limitedLengthElement], true);
            const selectors = [prepaidGiftCheckbox, prepaidLengthElement, prepaidTotalRecurrences, prepaidStartDate];
            this.toggleDisabledAttribute(selectors, true);
            if (groupData.recurring_mode.mixed) {
                this.ee.emit('mixed_product_recurring', { ro_product: this, recurring_orders_product: this, form: currentForm });
            }
        }
    }

    orderIntervalChanged(data) {
        const { data: { form } } = data;
        const selector = form.querySelector('.bold-ro__order-interval');
        if (selector) {
            const index = selector.selectedIndex;
            const element = selector.options[index];
            const frequencyNum = element.getAttribute('data-frequency-num');
            const frequencyType = element.getAttribute('data-frequency-type');
            const frequencyTypeText = element.getAttribute('data-frequency-type-text');
            if (frequencyNum) {
                form.querySelector('.bold-ro__frequency-num').value = frequencyNum;
            }
            form.querySelector('.bold-ro__frequency-type').value = frequencyType;
            form.querySelector('.bold-ro__frequency-type-text').value = frequencyTypeText;
        }
        this.updateShippingTooltips();
    }

    /**
     * Base properties key values to be added/removed on the forms
     * You can pass addition props as an object to add them to the base object;
     * @param  {Object} additionalProps Addition key values to be passed in. These should be set in properites constants file
     * @return {Object}
     */
    getProductCartProps(additionalProps) {
        const baseProps = {
            [propertyConst.FREQUENCY_NUM_KEY]: propertyConst.FREQUENCY_NUM_VALUE,
            [propertyConst.FREQUENCY_TYPE_KEY]: propertyConst.FREQUENCY_TYPE_VALUE,
            [propertyConst.FREQUENCY_TYPE_TEXT_KEY]: propertyConst.FREQUENCY_TYPE_TEXT_VALUE,
            [propertyConst.GROUP_ID_KEY]: propertyConst.GROUP_ID_VALUE,
            [propertyConst.DISCOUNTED_PRICE_KEY]: propertyConst.DISCOUNTED_PRICE_VALUE,
            [propertyConst.DISCOUNTED_PERCENTAGE_KEY]: propertyConst.DISCOUNTED_PERCENTAGE_VALUE,
            [propertyConst.UNFORMATTED_PRICE_KEY]: propertyConst.UNFORMATTED_PRICE_VALUE,
            [propertyConst.SECONDARY_DISCOUNT_NUM_KEY]: propertyConst.SECONDARY_DISCOUNT_NUM_VALUE,
            [propertyConst.SECONDARY_DISCOUNT_REQUIED_ORDERS_KEY]: propertyConst.SECONDARY_DISCOUNT_REQUIED_ORDERS_VALUE,
            [propertyConst.CONVERTIBLE_DISCOUNT_PERCENT_KEY]: propertyConst.CONVERTIBLE_DISCOUNT_PERCENT_VALUE,
            [propertyConst.CONVERTIBLE_DISCOUNT_PRODUCT_HANDLE_KEY]: propertyConst.CONVERTIBLE_DISCOUNT_PRODUCT_HANDLE_VALUE,
            [propertyConst.CONVERTIBLE_DISCOUNT_VARIANT_ID_KEY]: propertyConst.CONVERTIBLE_DISCOUNT_VARIANT_ID_VALUE,
            [propertyConst.CONVERTIBLE_PRODUCT_LINE_ITEM_PROPERTY_KEY]: propertyConst.CONVERTIBLE_PRODUCT_LINE_ITEM_PROPERTY_VALUE,
            [propertyConst.LIMITED_LENGTH_TOTAL_RECURRENCES_KEY]: propertyConst.LIMITED_LENGTH_TOTAL_RECURRENCES_VALUE,
        };

        if (additionalProps && Object.keys(additionalProps).length > 0) {
            return merge(additionalProps, baseProps);
        }
        return baseProps;
    }

    oneTimeProductSelected(data) {
        const { data: { form } } = data;
        if (form !== this.form) {
            return;
        }
        if (this.isCachedGroup(this.groupId) && !window.BOLD.recurring_orders.cached_group[this.groupId].subscription_only) {
            this.showCartButton(form);
            this.hideCustomButton(form);
            this.app.addToExisting.hideAddToExistingButton(form);
            this.hideWidgetElements(form);

            this.setSubscriptionState(false);

            const props = this.getProductCartProps();

            this.updateCartProperties(false, props, form);
            const limitedLengthSelector = form.querySelector('.bold-ro__limited-length-select');
            if (limitedLengthSelector) {
                limitedLengthSelector.removeAttribute('name');
            }
            form.setAttribute('action', '/cart/add');
        }
    }

    singleProductRecurring(data) {
        const { data: { form } } = data;
        const currentForm = form;
        if (currentForm !== this.form) {
            return;
        }
        this.setSubscriptionState(true);
        form.setAttribute('action', this.form_action);

        this.showCustomButton(currentForm);
        if (this.app.addToExisting.hasOrders()) {
            this.app.addToExisting.showAddToExistingButton(currentForm);
        }
        this.hideCartButton(currentForm);
        this.showWidgetElements(currentForm);

        this.setCustomButtonText(window.BOLD.recurring_orders.language.translations.complete_subscription);
        const props = this.getProductCartProps({ [propertyConst.CONVERTIBLE_PRODUCT_DETAILS_KEY]: propertyConst.CONVERTIBLE_PRODUCT_DETAILS_VALUE });
        this.updateCartProperties(true, props, currentForm);
        this.addCustomerInfo(currentForm);
        this.ee.emit('order_interval_changed', { form: currentForm, recurring_orders_product: this });

        const prepaidLengthSelector = form.querySelector('.bold-ro__prepaid-length-select');
        if (prepaidLengthSelector) {
            this.ee.emit('subscription_length_changed', {
                ro_product: this, recurring_orders_product: this, element: prepaidLengthSelector, form: currentForm,
            });
        }
    }

    subscriptionBoxRecurring(data) {
        const currentForm = data.data.form;
        if (currentForm !== this.form) {
            return;
        }
        this.setSubscriptionState(true);
        currentForm.setAttribute('action', this.form_action_sub_box);

        this.setCustomButtonText(window.BOLD.recurring_orders.language.translations.subscription_box_choices);
        const props = this.getProductCartProps();
        this.updateCartProperties(true, props, currentForm);
        this.showCustomButton(currentForm);
        this.hideCartButton(currentForm);
        this.showWidgetElements(currentForm);
        this.addCustomerInfo(currentForm);
        this.ee.emit('order_interval_changed', { form: currentForm, recurring_orders_product: this });
    }

    addCustomerInfo(form) {
        const { BOLD: { customer } } = window;
        if (typeof (customer) !== 'undefined') {
            map(CUSTOMER, (property) => {
                const customerKey = (property === 'shopify_customer_id') ? 'id' : property;
                const selector = form.querySelector(`[name="${property}"]`);
                if (!selector) {
                    const value = customer[customerKey] || '';
                    form.appendChild(this.createHiddenInput(property, value, 'bold-ro__customer_field'));
                }
            });
        }
    }

    createHiddenInput(name, value, classname) {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        input.className = `bold-ro__hidden_input ${name} ${classname}`;
        return input;
    }

    /**
     * Function that takes in a set off properties to be added or removed.
     *
     * @param  {Boolean} setAttributes - true add attribute, false removeAttribute
     * @param  {Object} properties    Set up key value pairs to be added as properties
     * @param  {HTMLEntinity} form          Form to querySelector on
     * @param  {Boolean} singleProductMode  default true will not set the name attribute with propertie.
     *                                      Set to false to add property to pass through to cart
     * @return {}
     */
    updateCartProperties(setAttributes, properties, form, singleProductMode = true) {
        if (setAttributes) {
            Object.keys(properties).forEach((key) => {
                if (form.querySelector(`.bold-ro__${key}`)) {
                    if (singleProductMode) {
                        form.querySelector(`.bold-ro__${key}`).setAttribute('name', properties[key]);
                    } else {
                        form.querySelector(`.bold-ro__${key}`).setAttribute('name', `properties[${properties[key]}]`);
                    }
                }
            });
        } else {
            Object.keys(properties).forEach((key) => {
                if (form.querySelector(`.bold-ro__${key}`)) {
                    form.querySelector(`.bold-ro__${key}`).removeAttribute('name');
                }
            });
        }
    }

    mixedProductRecurring(data) {
        const { data: { form } } = data;
        const currentForm = form;
        const props = this.getProductCartProps();
        this.showWidgetElements(currentForm);

        currentForm.setAttribute('action', '/cart/add');
        this.setSubscriptionState(true);
        this.showCartButton(currentForm);
        if (this.app.addToExisting.hasOrders()) {
            this.app.addToExisting.showAddToExistingButton(currentForm);
        }
        this.hideCustomButton(currentForm);
        this.updateCartProperties(true, props, currentForm, false);

        const limitedLengthSelector = currentForm.querySelector('.bold-ro__limited-length-select');
        if (limitedLengthSelector) {
            limitedLengthSelector.setAttribute('name', 'properties[total_recurrences]');
        }
        const prepaidLengthSelector = currentForm.querySelector('.bold-ro__prepaid-length-select');
        if (prepaidLengthSelector) {
            this.ee.emit('subscription_length_changed', {
                ro_product: this, recurring_orders_product: this, element: prepaidLengthSelector, form: currentForm,
            });
        }
        this.ee.emit('order_interval_changed', { form: currentForm, recurring_orders_product: this });
    }

    getCashierFields() {
        const frequencyNumSelector = this.form.querySelector(CLASS_SELECTOR_FREQUENCY_NUM);
        const frequencyTypeSelector = this.form.querySelector(CLASS_SELECTOR_FREQUENCY_TYPE);
        const frequencyTypeTextSelector = this.form.querySelector(CLASS_SELECTOR_FREQUENCY_TYPE_TEXT);
        const isPrepaidSelector = this.form.querySelector(CLASS_SELECTOR_PREPAID_CHECKBOX);
        const prepaidLengthSelector = this.form.querySelector(NAME_PREPAID_LENGTH_ID);
        const totalRecurrencesSelector = this.form.querySelector(NAME_TOTAL_RECURRENCES);
        const isGiftCheckbox = this.form.querySelector(NAME_IS_GIFT);
        const prepaidStartDateInput = this.form.querySelector(NAME_PREPAID_START_DATE);
        let number = null;
        let type = null;
        let typeText = null;
        if (frequencyNumSelector && frequencyTypeSelector && frequencyTypeTextSelector) {
            number = frequencyNumSelector.value;
            type = frequencyTypeSelector.value;
            typeText = frequencyTypeTextSelector.value;
        }

        let totalRecurrences = 0;
        if (totalRecurrencesSelector) {
            totalRecurrences = totalRecurrencesSelector.value;
        }
        let isPrepaidValue = 0;
        if (isPrepaidSelector) {
            isPrepaidValue = isPrepaidSelector.checked ? 1 : 0;
        }
        const prepalidLengthId = isPrepaidValue ? prepaidLengthSelector.value : 0;

        let isGift = 0;
        if (isGiftCheckbox) {
            isGift = isGiftCheckbox.checked || isGiftCheckbox.value === '1' ? 1 : 0;
        }

        let prepaidStartDate = null;
        if (prepaidStartDateInput) {
            prepaidStartDate = prepaidStartDateInput.value;
        }

        return {
            frequencyNum: number,
            frequencyType: type,
            frequencyTypeText: typeText,
            isPrepaid: isPrepaidValue,
            prepaidLengthId: prepalidLengthId,
            isGift,
            prepaidStartDate,
            totalRecurrences,
        };
    }

    /**
     * Function that generates an array of key value pairs from the properties that exist on the form
     *
     * @param  {Object} form
     *
     * @return {Array}
     */
    getFormProperties(form) {
        const properties = [];
        const formProperties = form.querySelectorAll('[name^="properties"]');

        if (formProperties) {
            formProperties.forEach((element) => {
                properties.push({ key: element.name, value: element.value });
            });
        }

        return properties;
    }

    singleProductCustomButtonClicked(data) {
        const { data: { form } } = data;
        // If the click event is not for the form where the button is located then don't continue
        if (this.form !== form || !this.app.cartWidget.checkoutEnabled) {
            return;
        }

        let quantity = 1;
        const groupId = window.BOLD.common.Shopify.variants[form.elements.id.value].group_id;
        const variantId = form.elements.id.value;
        const productIdSelector = form.querySelector('[name="product_id[]"]');
        if (productIdSelector) {
            productIdSelector.value = this.productId;
            form.querySelector('[name="variant_id[]"]').value = variantId;
            if (form.querySelector('[name="quantity"]') !== null) {
                quantity = form.querySelector('[name="quantity"]').value;
            }
            form.querySelector('[name="quantities[]"]').value = quantity;
        }
        const billingPlan = form.querySelector('[name="billing_plan"]');
        if (billingPlan) {
            const billingPlanSelectedIndex = form.querySelector('.bold-ro__billing-plan-select').selectedIndex;
            billingPlan.value = form.querySelector('.bold-ro__billing-plan-select').options[billingPlanSelectedIndex].value;
        }
        // Add check if customer is logged in and Not anonymous_login
        const currentGroupData = window.BOLD.recurring_orders.cached_group[groupId];
        let shopifyCustId = JSHelper.windowGet('BOLD.customer.id') !== 'undefined' ? JSHelper.windowGet('BOLD.customer.id') : null;
        const shopifyCustIdElem = form.querySelector('[name="shopify_customer_id"]');

        this.addRefersionTracking(form);

        if (shopifyCustIdElem && !shopifyCustId) {
            shopifyCustId = shopifyCustIdElem.value;
        }
        if (currentGroupData && !currentGroupData.anonymous_login && !shopifyCustId && !currentGroupData.is_cashier_linked) {
            if (!this.app.modal.isOpen) {
                this.app.modal.open('#bold-ro_login-modal');
            }
        } else if (this.hasValidFormFields(form)) {
            if (typeof currentGroupData !== 'undefined' && currentGroupData.is_cashier_linked && isCashierEnabled() && !currentGroupData.subscription_box) {
                const cashierFields = this.getCashierFields();
                const formProperties = this.getFormProperties(form);
                fetch('/cart/clear.js', {
                    method: 'POST',
                    credentials: 'same-origin',
                })
                    .then(() => {
                        const formData = new FormData();
                        formProperties.forEach((property) => {
                            formData.append(property.key, property.value);
                        });
                        formData.append('id', variantId);
                        formData.append('quantity', quantity);
                        formData.append(`properties[${propertyConst.FREQUENCY_NUM_VALUE}]`, cashierFields.frequencyNum);
                        formData.append(`properties[${propertyConst.FREQUENCY_TYPE_VALUE}]`, cashierFields.frequencyType);
                        formData.append(`properties[${propertyConst.FREQUENCY_TYPE_TEXT_VALUE}]`, cashierFields.frequencyTypeText);
                        formData.append(`properties[${propertyConst.IS_PREPAID_VALUE}]`, cashierFields.isPrepaid);
                        formData.append(`properties[${propertyConst.IS_GIFT_VALUE}]`, cashierFields.isGift);
                        formData.append(`properties[${propertyConst.GROUP_ID_VALUE}]`, groupId);
                        formData.append(`properties[${propertyConst.IS_RO_SINGLE_PRODUCT_RECURRING_ITEM_VALUE}]`, true);

                        if (cashierFields.isPrepaid && cashierFields.prepaidStartDate) {
                            formData.append(`properties[${propertyConst.PREPAID_START_DATE}]`, cashierFields.prepaidStartDate);
                        }

                        if (cashierFields.isPrepaid && cashierFields.totalRecurrences) {
                            formData.append(`properties[${propertyConst.TOTAL_RECURRENCES_VALUE}]`, cashierFields.totalRecurrences);
                        }
                        if (cashierFields.isPrepaid && cashierFields.prepaidLengthId) {
                            formData.append(`properties[${propertyConst.PREPAID_LENGTH_ID_VALUE}]`, cashierFields.prepaidLengthId);
                        }
                        if (billingPlan) {
                            formData.append(`properties[${propertyConst.RO_BILLING_PLAN_VALUE}]`, billingPlan.value);
                        }
                        const limitedLengthSelector = form.querySelector(CLASS_SELECTOR_LIMITED_LENGTH_TOTAL_RECURRENCES);
                        if (limitedLengthSelector) {
                            limitedLengthSelector.setAttribute('name', propertyConst.LIMITED_LENGTH_TOTAL_RECURRENCES_VALUE);
                            formData.append(`properties[${propertyConst.LIMITED_LENGTH_TOTAL_RECURRENCES_VALUE}]`, limitedLengthSelector.value);
                        }
                        if (currentGroupData.conversion) {
                            formData.append(
                                `properties[${propertyConst.CONVERTIBLE_PRODUCT_LINE_ITEM_PROPERTY_VALUE}]`,
                                generateConvertibleLineItemProperty(
                                    groupId,
                                    currentGroupData.conversion.variant_id,
                                    currentGroupData.conversion.discount,
                                    currentGroupData.conversion.product_details.handle,
                                ),
                            );
                        }
                        fetch('/cart/add.js', {
                            method: 'POST',
                            credentials: 'same-origin',
                            body: formData,
                        })
                            .then(() => {
                                fetch(`/cart.js?ts=${Date.now()}`, {
                                    credentials: 'same-origin',
                                })
                                    .then((resp) => resp.json())
                                    .then((cart) => {
                                        const cartCookie = getCookie('cart');
                                        const element = document.createElement('INPUT');
                                        element.type = 'HIDDEN';
                                        element.name = 'cart_id';
                                        element.value = cartCookie;
                                        const cartElement = document.createElement('INPUT');
                                        cartElement.type = 'HIDDEN';
                                        cartElement.name = 'cart';
                                        cartElement.value = JSON.stringify(cart);

                                        if (window.BOLD && window.BOLD.checkout && typeof window.BOLD.checkout.localTime === 'function') {
                                            const checkoutTimeElement = document.createElement('INPUT');
                                            checkoutTimeElement.type = 'HIDDEN';
                                            checkoutTimeElement.name = 'checkout_local_time';
                                            checkoutTimeElement.value = window.BOLD.checkout.localTime();
                                            form.appendChild(checkoutTimeElement);
                                        }

                                        if (window.BOLD && window.BOLD.checkout && typeof window.BOLD.checkout.languageIsoCode === 'string') {
                                            const languageCodeElement = document.createElement('INPUT');
                                            languageCodeElement.type = 'HIDDEN';
                                            languageCodeElement.name = 'language_iso';
                                            languageCodeElement.value = window.BOLD.checkout.languageIsoCode;
                                            form.appendChild(languageCodeElement);
                                        }

                                        form.appendChild(element);
                                        form.appendChild(cartElement);
                                        form.submit();
                                    });
                            });
                    });
            } else {
                this.addPickupEnabledToForm(form);
                form.submit();
            }
        }
    }

    addPickupEnabledToForm(form) {
        let pickupEnabledInput = form.querySelector('[name*="pickup_enabled"]');
        if (this.app.cartWidget.pickupEnabled) {
            if (pickupEnabledInput === null) {
                pickupEnabledInput = document.createElement('INPUT');
                pickupEnabledInput.name = 'pickup_enabled';
                pickupEnabledInput.type = 'hidden';
                pickupEnabledInput.value = 1;
                form.appendChild(pickupEnabledInput);
            } else {
                pickupEnabledInput.name = 'pickup_enabled';
                pickupEnabledInput.type = 'hidden';
                pickupEnabledInput.value = 1;
            }
        } else if (pickupEnabledInput !== null) {
            form.removeChild(pickupEnabledInput);
        }
    }

    addRefersionTracking(form) {
        const refersionCi = localStorage.getItem('rfsn_ci');
        const refersionAid = localStorage.getItem('rfsn_aid');
        const refersionID = localStorage.getItem('rfsn_v4_id');
        const refersionV4Aid = localStorage.getItem('rfsn_v4_aid');
        const refersionv4Cs = localStorage.getItem('rfsn_v4_cs');

        if (refersionID !== null
            && refersionV4Aid !== null
            && refersionv4Cs !== null
        ) {
            const shopifyCartToken = getCookie('cart');
            const refersionAttributeInput = document.createElement('input');
            refersionAttributeInput.type = 'hidden';
            refersionAttributeInput.name = 'attributes[refersion_cart_id]';
            refersionAttributeInput.value = shopifyCartToken;
            form.appendChild(refersionAttributeInput);

            const rfsnCdnURL = new URL(document.querySelector("script[src^='https://cdn.refersion.com/shop.js']").getAttribute('src'));
            const qs = new URLSearchParams(rfsnCdnURL.search);
            const rfsnPubKey = qs.get('key');

            const requestBody = {
                checkout_id: shopifyCartToken,
                id: refersionID,
                url: window.location.href,
                aid: refersionV4Aid,
                cs: refersionv4Cs,
            };

            fetch('https://tracking.refersion.com/checkout', {
                headers: { key: rfsnPubKey },
                body: JSON.stringify(requestBody),
                method: 'POST',
                mode: 'cors',
                credentials: 'omit',
            });
        } else if (refersionCi === null || refersionAid === null) {
            // no action needed
        } else {
            const refersionCookie = this.getRefersionCookie(refersionCi, refersionAid);
            const refersionPixelUrl = `https://www.refersion.com/tracker/shopify/v2?shop=${ShopifyHelper.getShopUrl()
            }&rci=${refersionCi
            }&raid=${refersionAid
            }&sci=${refersionCookie
            }&d=${new Date().getTime()
            }`;

            fetch(refersionPixelUrl);

            const refersionAttributeInput = document.createElement('input');
            refersionAttributeInput.type = 'hidden';
            refersionAttributeInput.name = 'attributes[refersion_cart_id]';
            refersionAttributeInput.value = refersionCookie;
            form.appendChild(refersionAttributeInput);
        }
    }

    getRefersionCookie(refersionCi, refersionAid) {
        const refersionCookieKey = `ro_rfsn_cookie_${refersionCi}_${refersionAid}`;
        let cookie = localStorage.getItem(refersionCookieKey);

        if (cookie === null) {
            const id = [];
            const possible = 'abcdef0123456789';

            for (let i = 0; i < 32; i += 1) {
                id.push(possible.charAt(Math.floor(Math.random() * possible.length)));
            }

            cookie = id.join('');
            localStorage.setItem(refersionCookieKey, cookie);
        }

        return cookie;
    }

    validateStartDate(e) {
        const selectedDate = new Date(e.target.value);
        const currentDate = new Date();
        if (currentDate >= selectedDate) {
            e.target.value = e.target.min;
        }
    }

    validateNumber(e) {
        e.target.value = Math.min(Math.max(parseInt(e.target.value, 10), e.target.min), e.target.max);
    }

    hasValidFormFields(form) {
        let result = true;
        const frequencyNumSelector = form.querySelector('.bold-ro__frequency-num');
        frequencyNumSelector.classList.remove('bold-ro__error');
        if (frequencyNumSelector && frequencyNumSelector.type === 'number' && Number.isNaN(parseInt(frequencyNumSelector.value, 10))) {
            frequencyNumSelector.classList.add('bold-ro__error');
            result = false;
        }
        return result;
    }

    isSingleProductRecurring() {
        const singleProductClass = this.form.querySelector('.bold-ro__recurring-radio-btn');
        let result = false;
        if (singleProductClass) {
            result = true;
        }
        return result;
    }

    disableCustomButton(form) {
        map(form.querySelectorAll(`${CLASS_SELECTOR_CUSTOM_BUTTON}${this.productId}`), (button) => {
            button.setAttribute('disabled', true);
        });
    }

    enableCustomButton(form) {
        map(form.querySelectorAll(`${CLASS_SELECTOR_CUSTOM_BUTTON}${this.productId}`), (button) => {
            button.removeAttribute('disabled');
        });
    }

    showCustomButton(form) {
        map(form.querySelectorAll(`${CLASS_SELECTOR_CUSTOM_BUTTON}${this.productId}`), (button) => {
            button.removeAttribute('style');
        });
    }

    hideCustomButton(form) {
        map(form.querySelectorAll(`${CLASS_SELECTOR_CUSTOM_BUTTON}${this.productId}`), (button) => {
            button.setAttribute('style', 'display: none;');
        });
    }

    setCustomButtonText(text) {
        map(this.form.querySelectorAll(`${CLASS_SELECTOR_CUSTOM_BUTTON}${this.productId}`), (button) => {
            button.innerText = text;
        });
    }

    hideCartButton(form) {
        map(form.querySelectorAll('.cartbutton'), (button) => {
            button.setAttribute('style', 'display: none;');
        });
    }

    showCartButton(form) {
        map(form.querySelectorAll('.cartbutton'), (button) => {
            button.removeAttribute('style');
        });
    }

    cartButtonIsDisabled(form) {
        return form.querySelector('.cartbutton').hasAttribute('disabled');
    }

    updateDetailTooltips() {
        map(this.detailTooltips, (tt) => {
            tt.dispose();
        });
        this.detailTooltips = [];
        map(this.form.querySelectorAll('.bold-ro__detail-tooltip'), (el) => {
            el.className = `${el.className} needsclick`;
            const tt = new Tooltip(el, {
                placement: window.BOLD.recurring_orders.settings.toolTipLocation,
                title: window.BOLD.recurring_orders.language.display_settings.hover_modal,
                html: true,
                template: HTML_TOOLTIP_TEMPLATE,
                trigger: window.BOLD.recurring_orders.settings.toolTipTrigger,
            });

            this.detailTooltips.push(tt);
        });
    }

    updateShippingTooltips() {
        // wipe out references to existing shipping tooltips
        map(this.shippingTooltips, (tt) => {
            tt.dispose();
        });
        this.shippingTooltips = [];

        let shippingText = 'N/A';

        const billingPlanDay = this.form.querySelector('.bold-ro__billing-plan-select');

        if (billingPlanDay) {
            const planIndex = billingPlanDay.selectedIndex;
            const hover = get(this.billingPlans[planIndex], 'shipping_schedule_hover_enable', {});
            const frequencyNum = this.form.querySelector('.bold-ro__frequency-num');
            if (hover.show && hover.next_dates && frequencyNum) {
                try {
                    const nextDate = new Date(Date.parse(`${hover.next_dates[frequencyNum.value]} 00:00:00`));
                    const intlOptions = { year: 'numeric', month: 'short', day: 'numeric' };
                    const intlObj = new Intl.DateTimeFormat(navigator.language, intlOptions);
                    const templatePattern = /\\?\[(?:\s*([\w\-.]+)\s*)(?::([^\\[\]]+))?\]/g;
                    const placeholders = {
                        '[interval]': frequencyNum.value,
                        '[next_ship_date_Ymd]': intlObj.format(nextDate),
                        '[next_ship_date_mdY]': intlObj.format(nextDate),
                    };
                    shippingText = hover.text.replace(templatePattern, (matched) => (!placeholders[matched] ? matched : placeholders[matched]));
                } catch (e) {
                    //
                }
            } else {
                shippingText = hover.text;
            }

            // loop through tooltip elements, re-initializing them all.
            map(this.form.querySelectorAll('.bold-ro__shipping-tooltip'), (el) => {
                el.className = `${el.className} needsclick`;
                this.shippingTooltips.push(new Tooltip(el, {
                    placement: window.BOLD.recurring_orders.settings.toolTipLocation,
                    title: shippingText,
                    html: true,
                    template: HTML_TOOLTIP_TEMPLATE,
                    trigger: window.BOLD.recurring_orders.settings.toolTipTrigger,
                }));
            });
        }
    }

    isCachedGroup(groupId) {
        if (
            typeof window.BOLD.recurring_orders.cached_group !== 'undefined'
            && typeof window.BOLD.recurring_orders.cached_group[groupId] !== 'undefined'
        ) {
            return true;
        }
        return false;
    }

    reload() {
        const { BOLD: { common: { Shopify: { variants } } } } = window;
        this.setVariantId(this.form.id.value);
        if (variants[this.variantId]
            && isValidGroup(variants[this.variantId].group_id)) {
            this.setGroupId(variants[this.variantId].group_id);
            if (this.isCachedGroup(this.groupId)) {
                const groupData = window.BOLD.recurring_orders.cached_group[this.groupId];
                this.init(groupData, this);
                this.cleanUpOldWidget(this.form);
            } else {
                this.app.startLoading(this.form);
                this.getWidget().then((resp) => {
                    this.init(resp, this);
                    this.cleanUpOldWidget(this.form);
                    this.app.stopLoading(this.form);
                }).catch((e) => { console.error(`Error on variant change. Unable to receive response from ${window.BOLD.recurring_orders.path}api_public/group/${this.groupId}?shop_url=${ShopifyHelper.getShopUrl()} Error: ${e}`); });
            }
        } else if (typeof this.DOM !== 'undefined' && typeof this.DOM.element !== 'undefined') {
            this.DOM.element.remove();
            this.form.action = '/cart/add';
            this.hideCustomButton(this.form);
            this.app.addToExisting.hideAddToExistingButton(this.form);
            this.showCartButton(this.form);
            this.app.stopLoading(this.form);
        }
    }

    cleanUpOldWidget(form) {
        // Temporary: Hide Add to Existing for Convertible Subs
        this.hideAddToExisting(form);

        // Temporary: Until we move everything to new widget
        this.hideOldWidget(form);
    }

    hideOldWidget(form) {
        map(form.querySelectorAll('.product_rp_div'), (div) => {
            div.innerHTML = '';
        });
    }

    hideAddToExisting(form) {
        map(form.querySelectorAll('.bold_add_to_orders'), (div) => {
            div.setAttribute('style', 'display: none;');
        });
    }

    removeWidget(resp) {
        const { data: { recurring_orders_product, form } } = resp;
        if (recurring_orders_product.DOM && recurring_orders_product.DOM.element) {
            recurring_orders_product.DOM.element.remove();
            this.hideCustomButton(form);
            this.showCartButton(form);
        }
        this.app.stopLoading(form);
    }

    initializeTemplate(fields) {
        if (
            typeof this.DOM !== 'undefined'
            && typeof this.DOM.element !== 'undefined'
            && this.DOM.parent.hasChildNodes()
        ) {
            this.DOM.setFields(fields);
            this.DOM.templateType = this.getTemplateType(fields);
            this.DOM.refreshTemplate();
            this.ee.emit('template_refreshed', { recurring_orders_product: this });
        } else {
            this.DOM = new DOMHandler(this.getTemplateType(fields), fields, this.element);
            this.ee.emit('template_initialized', { recurring_orders_product: this });
        }
    }

    getTomorrowDate() {
        let currentDate = new Date();
        let currentDay = currentDate.getDate() + 1;
        let currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        if (currentDay < 10) {
            currentDay = `0${currentDay}`;
        }
        if (currentMonth < 10) {
            currentMonth = `0${currentMonth}`;
        }

        currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        return currentDate;
    }

    getTemplateType(fields) {
        let result = null;
        switch (fields.subscription_type) {
        case SUBSCRIPTION_CONVERTIBLE_NUM:
            result = TEMPLATE_SUBSCRIPTION_STANDARD;
            break;
        case SUBSCRIPTION_BOX_NUM:
            result = TEMPLATE_SUBSCRIPTION_BOX;
            break;
        default:
            result = TEMPLATE_SUBSCRIPTION_STANDARD;
            break;
        }
        return result;
    }

    addTemplateFunctions(fields, form) {
        const { BOLD: { recurring_orders: { language } } } = window;

        this.shopUrl = fields.shop_url;
        this.billingPlans = get(fields, 'billing_plans', {});
        if (this.subscription_checked || fields.subscription_only || fields.is_subscription_default_on_widget) {
            fields.subscription_checked = true;
            if (this.isSingleProductRecurring()) {
                this.hideCartButton(form);
            }
        } else {
            this.showCartButton(form);
        }
        fields.unformatted_discount_price = calculateDiscountPercentPrice(fields.group_discount, fields.price);
        fields.unformatted_discount_amount = calculateDiscountPercentAmount(fields.group_discount, fields.price);
        fields.discount_price = ShopifyHelper.displayMoney(fields.unformatted_discount_price, fields.money_format);

        if (fields.conversion) {
            fields.conversion.unformatted_discount_price = calculateDiscountPercentPrice(fields.conversion.discount, fields.conversion.price);
            fields.conversion.unformatted_discount_amount = calculateDiscountPercentAmount(fields.conversion.discount, fields.conversion.price);
            const conversionProductDetails = {
                product_details: fields.conversion.product_details,
                price: fields.conversion.price,
            };
            fields.conversion.stringified_conversion = JSON.stringify(conversionProductDetails);
            fields.conversion.line_item_property = generateConvertibleLineItemProperty(
                fields.group_id,
                fields.conversion.variant_id,
                fields.conversion.discount,
                fields.conversion.product_details.handle,
            );
        }

        if (fields.secondary_discount) {
            fields.secondary_discount.unformatted_discount_amount = calculateDiscountPercentAmount(fields.secondary_discount.discount, fields.price);
            fields.secondary_discount.unformatted_discount_price = calculateDiscountPercentPrice(fields.secondary_discount.discount, fields.price);
        }

        commonMergeFieldFunctions(fields);

        fields.hide_no_discount = () => (text, render) => {
            let value = render(text);
            if (fields.conversion.discount <= 0.00) {
                value = '';
            }
            return value;
        };

        fields.merge_one_time_price = () => (text, render) => {
            const textVal = render(text);
            const oneTimePrice = ShopifyHelper.displayMoney(fields.price, fields.money_format);
            return render(textVal.replace(
                /\[one_time_price\]/g,
                () => `<span class="bold-ro__one-time-price">${oneTimePrice}</span>`,
            ));
        };

        fields.merge_conversion_discount_percent = () => (text, render) => {
            const textVal = render(text);
            const discountPercent = Math.floor(fields.conversion.discount * 100) / 100;
            const recurringDiscountPercent = `${discountPercent}%`;
            const recurringDiscountedPrice = ShopifyHelper.displayMoney(fields.conversion.unformatted_discount_price, fields.money_format);
            const recurringDiscountAmount = ShopifyHelper.displayMoney(fields.conversion.unformatted_discount_amount, fields.money_format);
            const initialDiscountPercentVal = Math.floor(fields.conversion.group_discount * 100) / 100;
            const initialDiscountPercent = `${initialDiscountPercentVal}%`;
            const initialDiscountedPrice = ShopifyHelper.displayMoney(fields.unformatted_discount_price, fields.money_format);
            const initialDiscountAmount = ShopifyHelper.displayMoney(fields.unformatted_discount_amount, fields.money_format);
            return render(textVal.replace(/\[initial_discount_percentage\]/g, initialDiscountPercent)
                .replace(/\[recurring_discount_percentage\]/g, recurringDiscountPercent)
                .replace(/\[inital_discount_price\]/g, () => initialDiscountedPrice)
                .replace(/\[recurring_discount_price\]/g, () => recurringDiscountedPrice)
                .replace(/\[inital_discount_amount\]/g, () => initialDiscountAmount)
                .replace(/\[recurring_discount_amount\]/g, () => recurringDiscountAmount));
        };

        fields.merge_secondary_discount = () => (text, render) => {
            const textVal = render(text);
            const discountPercent = Math.floor(fields.secondary_discount.discount * 100) / 100;
            const secondaryDiscountPercent = `<span class="bold-ro__secondary-discount-percent">${discountPercent}%</span>`;
            const secondaryDiscountAmount = `<span class="bold-ro__secondary-discounted-amount">${ShopifyHelper.displayMoney(fields.secondary_discount.unformatted_discount_amount, fields.money_format)}</span>`;
            const secondaryDiscountPrice = `<span class="bold-ro__secondary-discount-price">${ShopifyHelper.displayMoney(fields.secondary_discount.unformatted_discount_price, fields.money_format)}</span>`;
            const numRequiredOrders = (fields.secondary_discount) ? fields.secondary_discount.num_required_orders : '';
            return render(textVal.replace(/\[discount_percentage\]/g, secondaryDiscountPercent)
                .replace(/\[amount_discounted\]/g, () => secondaryDiscountAmount)
                .replace(/\[discount_price\]/g, () => secondaryDiscountPrice)
                .replace(/\[num_orders\]/g, numRequiredOrders));
        };

        fields.merge_initial_discount = () => (text, render) => {
            const textVal = render(text);
            const discountPercent = Math.floor(fields.group_discount * 100) / 100;
            const initialGroupDiscount = `<span class="bold-ro__initial-discount-percent">${discountPercent}%</span>`;
            const initialDiscountAmount = `<span class="bold-ro__initial-discounted-amount">${ShopifyHelper.displayMoney(fields.unformatted_discount_amount, fields.money_format)}</span>`;
            const initialDiscountPrice = `<span class="bold-ro__initial-discount-price">${ShopifyHelper.displayMoney(fields.unformatted_discount_price, fields.money_format)}</span>`;
            const numRequiredOrders = (fields.secondary_discount) ? fields.secondary_discount.num_required_orders : '';
            return render(textVal.replace(/\[num_orders\]/g, numRequiredOrders)
                .replace(/\[discount_percentage\]/g, initialGroupDiscount)
                .replace(/\[amount_discounted\]/g, () => initialDiscountAmount)
                .replace(/\[discount_price\]/g, () => initialDiscountPrice));
        };

        fields.merge_interval = () => (text, render) => {
            const textVal = render(text);
            const result = textVal.replace(/\[interval\]/g, language.translations[fields.frequency_type[0].frequency_type_translation]);
            return render(result);
        };

        fields.merge_interval_number = () => (text, render) => {
            const textVal = render(text);
            let result = '';
            if (!fields.fixed_interval) {
                let frequencyInput = '';
                if (fields.frequency_too_large) {
                    frequencyInput = `<input type="number" id="frequency_num" name="frequency_num" class="bold-ro__frequency-num" value="1" min="1" max="${fields.frequency_num[0].frequency_num_id}">`;
                } else {
                    frequencyInput = '<select class="bold-ro__frequency-num" name="frequency_num">';
                    map(fields.frequency_num, (num) => {
                        frequencyInput += `<option data-frequency-num="${num.frequency_num_id}" value="${num.frequency_num_id}">${num.frequency_num_id}</option>`;
                    });
                    frequencyInput += '</select>';
                }
                result = textVal.replace(/\[interval_number\]/g, frequencyInput);
            } else {
                result = textVal.replace(/\[interval_number\]/g, fields.frequency_num[0].frequency_num_id);
            }
            return render(result);
        };

        fields.merge_billing_day = () => (text, render) => {
            const textVal = render(text);
            const result = textVal.replace(/\[billing_day\]/g, '');
            return render(result);
        };

        fields.merge_prepaid = () => (text, render) => {
            const textVal = render(text);
            const prepaidPercentText = '<span class="bold-ro__prepaid-percent"></span>';
            const prepaidPriceText = '<span class="bold-ro__prepaid-discount-price"></span>';
            const prepaidAmountText = '<span class="bold-ro__prepaid-discount-amount"></span>';
            const result = textVal.replace(/\[discount_percentage\]/g, prepaidPercentText)
                .replace(/\[discount_price\]/g, () => prepaidPriceText)
                .replace(/\[discount_amount\]/g, () => prepaidAmountText);
            return render(result);
        };

        fields.current_date = this.getTomorrowDate();

        return fields;
    }

    getForm(form) {
        if (form === null) {
            return ShopifyHelper.findFormFromChild(this.element);
        }
        return form;
    }

    getElement(element, form) {
        if (!DOMHelper.hasClass(element, 'ro_widget')) {
            return this.createROElement(form);
        }
        return element;
    }

    /**
     * Adds the widget onto the product form
     *
     * @param form
     * @returns {Element}
     */
    createROElement(form) {
        const ele = document.createElement('div');
        ele.className = 'ro_widget';
        const oldROWidget = form.querySelector('.product_rp_div');
        if (oldROWidget) {
            const parentNodeOldWidget = oldROWidget.parentNode;
            parentNodeOldWidget.insertBefore(ele, oldROWidget);
        } else {
            const addToCartSelector = form.querySelector('.addtocart');
            if (addToCartSelector) {
                const parentNodeAddCart = addToCartSelector.parentNode;
                parentNodeAddCart.insertBefore(ele, form.querySelector('.addtocart'));
            } else {
                form.insertBefore(ele, form.firstChild);
            }
        }
        return ele;
    }

    getProductId(productId) {
        if (productId === null) {
            productId = this.getProductIdFromVariantSelector() || this.getProductIdFromHandle();
            if (this.app.isValidProductId(productId)) {
                return productId;
            }
        } else if (!Number.isNaN(productId)) {
            return productId;
        }
        return false;
    }

    getProductIdFromVariantSelector() {
        if (typeof this.form.id !== 'undefined') {
            return ShopifyHelper.getProductId(parseInt(this.form.elements.id.value, 10));
        }

        if (typeof this.form['id[]'] !== 'undefined') {
            return ShopifyHelper.getProductId(parseInt(this.form['id[]'].value, 10));
        }

        return false;
    }

    getProductIdFromHandle() {
        if (this.app.page === 'product') {
            const handle = ShopifyHelper.getProductHandle();
            if (handle) {
                const productId = ShopifyHelper.getProductIdByHandle(handle);
                if (productId) {
                    return productId;
                }
            }
        }
        return false;
    }

    unload() {
        this.element.parentNode.removeChild(this.element);
        remove(this.app.productForms, (n) => n === this.form);
    }

    removeAllListeners() {
        this.ee.removeListener('variant_changed', this.reload);
        this.ee.removeListener('no_widget_loaded', this.removeWidget);
        this.ee.removeListener('shipping_plan_changed', this.updateShippingTooltips);
        this.ee.removeListener('template_initialized', this.updateShippingTooltips);
        this.ee.removeListener('template_initialized', this.updateDetailTooltips);
        this.ee.removeListener('template_initialized', this.removeLoading);
        this.ee.removeListener('template_refreshed', this.updateDetailTooltips);
        this.ee.removeListener('template_refreshed', this.updateShippingTooltips);
        this.ee.removeListener('template_refreshed', this.removeLoading);
        this.ee.removeListener('mixed_product_recurring', this.mixedProductRecurring);
        this.ee.removeListener('one_time_product_selected', this.oneTimeProductSelected);
        this.ee.removeListener('single_product_recurring', this.singleProductRecurring);
        this.ee.removeListener('subscription_box_recurring', this.subscriptionBoxRecurring);
        this.ee.removeListener('order_interval_changed', this.orderIntervalChanged);
        this.ee.removeListener('subscription_length_changed', this.subscriptionLengthChanged);
        this.ee.removeListener('single_product_custom_button_clicked', this.singleProductCustomButtonClicked);
        this.ee.removeListener('prepaid_changed', this.prepaidChanged);
        this.ee.removeListener('prepaid_start_date_changed', this.prepaidStartDateChanged);
    }
}

export default RecurringOrdersProduct;
