const billingPlan = `
{{#recurring_mode.single_product}}
    <input type="hidden" name="billing_plan" value="">
{{/recurring_mode.single_product}}
<span class="bold-ro__billing-plan-text" {{^subscription_checked}} style="display:none;" {{/subscription_checked}}>
    {{#merge_billing_day}}{{#merge_interval}}{{#merge_interval_number}}{{#translate}}billing_plan_select{{/translate}}{{/merge_interval_number}}{{/merge_interval}}{{/merge_billing_day}}
</span>
<select class="bold-ro__billing-plan-select" {{^subscription_checked}} style="display:none;" {{/subscription_checked}}>
    {{#billing_plans}}
    <option value="{{id}}">{{#translate_days}}{{day_translation}}{{/translate_days}}</option>
    {{/billing_plans}}
</select>
{{#shipping_schedule_hover_enable}}<div class="bold-ro__billing-plan__tooltip-div"><a style="display:none" href="javascript:;" class="bold-ro__shipping-tooltip">{{#translate}}shipping_schedule{{/translate}}</a></div>{{/shipping_schedule_hover_enable}}
`;

export default billingPlan;
