import { ShopifyHelper } from '@bold-commerce/bold-common-js';
import { isLocalStorageNameSupported } from './HelperFunctions';

// fake local storage for tests
let { localStorage } = window;

if (localStorage === undefined) {
    localStorage = {};
    const removeItem = (key) => { delete localStorage[key]; };
    const clear = () => { localStorage = { removeItem, clear }; };
    localStorage.removeItem = removeItem;
    localStorage.clear = clear;
}

class RecurringOrdersAPI {
    constructor() {
        this.cachedCalls = {};
        this.localStoragePrefix = 'RO';
        this.cacheTimeToLive = 5 * 60 * 1000; // expire time in ms
        this.shouldUseStore = isLocalStorageNameSupported();
        this.debugging = false;
    }

    init() {
        this.defaultQuery = {
            shop_url: ShopifyHelper.getShopUrl(),
            _: window.BOLD.common.cacheParams.recurring_orders,
        };
        this.apiDomain = window.BOLD.recurring_orders.path;
        this.apiRoot = 'api_public/';

        this.proxyDomain = `https://${window.BOLD.common.Shopify.shop.domain}`;
        this.proxyApiRoot = '/tools/checkout/api_public/';

        this.initialized = true;
    }

    clearAll() {
        this.cachedCalls = {};
        localStorage.clear();
    }

    retrieve({
        endpoint, query, responseType = 'json', noCache = false, proxyRequest = false,
    }) {
        if (!this.initialized) { this.init(); }

        let useLocalStorage = true;
        if (noCache === true || !this.shouldUseStore) {
            useLocalStorage = false;
        }
        const callKeyArray = [this.localStoragePrefix, responseType, endpoint];

        const finalQuery = { ...this.defaultQuery, ...query };
        const queryKeys = Object.keys(finalQuery);
        const queryArray = [];

        if (queryKeys.length > 0) {
            // alphabetical sort
            queryKeys.sort((a, b) => a.localeCompare(b));

            queryKeys.forEach((key) => {
                const value = finalQuery[key];
                callKeyArray.push(key);
                callKeyArray.push(value);
                queryArray.push(`${key}=${value}`);
            });
        }

        const callKey = callKeyArray.join('_');
        let cachedCall = this.cachedCalls[callKey];

        let apiURL = `${this.apiDomain}${this.apiRoot}${endpoint}?${encodeURI(queryArray.join('&'))}`;
        if (proxyRequest === true) {
            apiURL = `${this.proxyDomain}${this.proxyApiRoot}${endpoint}?${encodeURI(queryArray.join('&'))}`;
        }

        if (!cachedCall || !cachedCall.dateAdded || (Date.now() - cachedCall.dateAdded > this.cacheTimeToLive) || noCache) {
            this.debug(endpoint, 'not in memory, stale in memory, or no cache mode enabled');
            cachedCall = {};

            cachedCall.promise = new Promise((resolve, reject) => {
                if (useLocalStorage) {
                    let localStorageResult = localStorage[callKey];
                    if (localStorageResult !== undefined) {
                        this.debug(endpoint, 'found in localStorage');
                        localStorageResult = JSON.parse(localStorageResult);
                        if ((new Date()).getTime() - localStorageResult.dateAdded > this.cacheTimeToLive) {
                            this.debug(endpoint, 'old localStorage');
                            localStorage.removeItem(callKey);
                        } else {
                            this.debug(endpoint, 'fresh localStorage');
                            let { value } = localStorageResult;

                            if (responseType === 'json') {
                                value = JSON.parse(value);
                            }

                            resolve(value);
                            return;
                        }
                    } else {
                        this.debug('not found in localStorage');
                    }
                }

                fetch(apiURL, { credentials: 'same-origin' })
                    .then((response) => {
                        if (response.status >= 400) {
                            this.debug(endpoint, 'bad response');
                            console.error('RecurringOrdersAPI fetch error:', response);
                            cachedCall.dateAdded = (new Date()).getTime();
                            reject(response);
                            return;
                        }
                        if (responseType === 'json') {
                            response.json().then((responseJSON) => {
                                const dateAdded = (new Date()).getTime();
                                if (useLocalStorage) {
                                    localStorage[callKey] = JSON.stringify({
                                        dateAdded,
                                        value: JSON.stringify(responseJSON),
                                    });
                                }
                                cachedCall.dateAdded = dateAdded;
                                resolve(responseJSON);
                            }).catch((e) => {
                                console.error('RecurringOrdersAPI json error:', e);
                                reject(e);
                            });
                        } else {
                            response.text().then((responseText) => {
                                const dateAdded = (new Date()).getTime();
                                if (useLocalStorage) {
                                    localStorage[callKey] = JSON.stringify({
                                        dateAdded,
                                        value: responseText,
                                    });
                                }
                                cachedCall.dateAdded = dateAdded;
                                resolve(responseText);
                            });
                        }
                    });
            });

            this.cachedCalls[callKey] = cachedCall;
        } else {
            this.debug(endpoint, 'in memory');
        }

        return cachedCall.promise;
    }

    debug(...params) { if (this.debugging) { console.log.apply(window, params); } }
}

const recurringOrdersAPI = new RecurringOrdersAPI();
export default recurringOrdersAPI;
