const widgetLoading = `
<span class="bold-ro__loading-container">
<style>
.bold-ro__widget-loading {
    margin-left: 45%;
}
.bold-ro__widget-loading-icon {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-bottom: 20px;
}
</style>
    <div data-bold-widget-loading-icon class="bold-ro__widget-loading-icon"></div>
</span>
`;

export default widgetLoading;
