const prepaid = `
<div class="bold-ro__prepaid-container" {{^subscription_checked}} style="display:none;" {{/subscription_checked}}>

    <div class="bold-ro__prepaid-length" {{^is_prepaid_only}}style="display:none"{{/is_prepaid_only}}>
        {{^limited_subscription}}
        <span class="bold-ro__limited-text">{{#translate}}subscription_length{{/translate}}</span>
        <select name="total_recurrences" class="bold-ro__subscription-length bold-ro__prepaid-total-recurrences" style="display:none;" disabled="true">
            {{#prepaid.lengths}}
            <option value="{{length}}">{{length}}</option>
            {{/prepaid.lengths}}
        </select>
        {{/limited_subscription}}

        <select name="prepaid_length_id" class="bold-ro__prepaid-length-select bold-ro__subscription-length" disabled="true">
            {{#prepaid.lengths}}
            <option data-discount-percentage="{{discount}}" data-length="{{length}}" value="{{id}}">{{length}}</option>
            {{/prepaid.lengths}}
        </select>
    </div>

    <div class="bold-ro__prepaid-checkbox-container">
        <input {{^is_prepaid_only}}type="checkbox"{{/is_prepaid_only}} name="is_prepaid" 
            {{#is_prepaid_only}}type="hidden" disabled="disabled" checked="checked"{{/is_prepaid_only}} 
            class="bold-ro__checkbox bold-ro__prepaid-checkbox">{{^is_prepaid_only}}<label for="is_prepaid" class="bold-ro__prepaid-checkbox-lbl">{{#merge_prepaid}}{{#translate}}prepay_for_your_subscription{{/translate}}<span class="bold-ro__prepaid-discount-text">{{#translate}}discount{{/translate}}</span>{{/merge_prepaid}}</label>{{/is_prepaid_only}}
    </div>
    
    {{#is_prepaid_always_expires}}
    <input type="hidden" name="is_gift" value="1" disabled="true" data-is-prepaid-always-expires class="bold-ro__checkbox bold-ro__prepaid-always-expires">
    {{/is_prepaid_always_expires}}

    {{#allow_prepaid_start_date}}
    {{#is_cashier_linked}}
    <div class="bold-ro__prepaid-start-date-option-container" {{^is_prepaid_only}}style="display:none"{{/is_prepaid_only}}>
        <input type="checkbox" name="prepaid_start_date_checkbox" class="bold-ro__checkbox bold-ro__prepaid-start-date-checkbox"><label for="start_date_checkbox" class="bold-ro__prepaid-start-date-lbl"><span class="bold-ro__prepaid-start-date-text">{{#translate}}prepaid_start_date_option_label{{/translate}}</span></label>
        <div class="bold-ro__prepaid-start-date-container" style="display: none">
            <input type="date" class="bold-ro__prepaid-start-date" min={{current_date}}>
        </div>
    </div>
    {{/is_cashier_linked}}
    {{/allow_prepaid_start_date}}

    {{#prepaid.enable_is_gift}}
    <div class="bold-ro__prepaid-gift-container" style="display:none">
    <input type="checkbox" name="is_gift" disabled="true" class="bold-ro__checkbox bold-ro__prepaid-gift-checkbox"><label for="is_gift" class="bold-ro__prepaid-gift-lbl">{{#translate}}is_this_a_gift{{/translate}}</label>
    </div>
    {{/prepaid.enable_is_gift}}
    
    <div class="bold-ro__prepaid-total-container" {{^is_prepaid_only}}style="display:none;"{{/is_prepaid_only}}>
        <span class="bold-ro__prepaid-total-label">{{#translate}}prepaid_total_price{{/translate}}</span><span class="bold-ro__prepaid-total"></span>
    </div>
</div>
`;

export default prepaid;
