import { BoldHelpers } from '@bold-commerce/bold-common-js';
import RecurringOrdersBootstrap from './AppClasses/RecurringOrdersBootstrap';
import { getCheckoutProcessingStatus } from './AppClasses/HelperFunctions';
import { loadTemplates } from './templates';
import { waitForCheckoutObject } from './helpers/cashierHelpers';

// Polyfill matches for Edge support
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.matchesSelector
        || Element.prototype.mozMatchesSelector
        || Element.prototype.msMatchesSelector
        || Element.prototype.oMatchesSelector
        || Element.prototype.webkitMatchesSelector
        || function (s) {
            const matches = (this.document || this.ownerDocument).querySelectorAll(s);
            let i = matches.length - 1;
            while (i >= 0 && matches.item(i) !== this) {
                i -= 1;
            }
            return i > -1;
        };
}

// Polyfill for find in IE
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', { //eslint-disable-line
        value(predicate, ...args) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            const o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            const len = Math.floor(Math.max(0, o.length));

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            const thisArg = args[1];

            // 5. Let k be 0.
            let k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                const kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k += 1;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true,
    });
}

// Polyfill for closest for IE and Safari 8
if (window.Element && !Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        const matches = (this.document || this.ownerDocument).querySelectorAll(s);
        let el = this;
        let i;

        do {
            i = matches.length;
            while (i > 0) {
                i -= 1;
                if (matches.item(i) === el) {
                    return el;
                }
            }
            el = el.parentElement;
        } while (el);

        return null;
    };
}

// Polyfill for remove
(function (arr) {
    arr.forEach((item) => {
        item.remove = item.remove
            || function () {
                if (this.parentNode !== 'undefined' && this.parentNode !== null) {
                    this.parentNode.removeChild(this);
                }
            };
    });
}([Element.prototype, CharacterData.prototype, DocumentType.prototype]));

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i += 1) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

window.BOLD = window.BOLD || {};
window.BOLD.recurring_orders = window.BOLD.recurring_orders || {};
window.BOLD.recurring_orders.cached_group = window.BOLD.recurring_orders.cached_group || {};

window.BOLD.recurring_orders.version = () => process.env.version;

require('./settings');
require('./AppClasses/HelperConvertibleDesc');

loadTemplates();

window.BOLD.recurring_orders.path = process.env.paths.ro_path;
window.BOLD.recurring_orders.polyfillPath = process.env.paths.polyfillPath;
window.BOLD.recurring_orders.application_uuid = process.env.application_uuid;

if (typeof window.BOLD.common === 'undefined') {
    console.error('BOLD.common not found. snippets/bold-common.liquid does not exist or is not included.');
} else {
    getCheckoutProcessingStatus()
        .then((status) => {
            if (status.disabled) {
                console.warn('%cCheckout processing has been disabled as part of the Shopify Policy that no longer allows checkouts other than Shopify Checkout to process subscriptions.', 'color: magenta');
                waitForCheckoutObject().then((checkout) => {
                    checkout.disable();
                    console.warn('%cCashier checkout has been disabled as part of the Shopify Policy that no longer allows checkouts other than Shopify Checkout to process subscriptions.', 'color: magenta');
                });
            } else {
                window.BOLD.common.cacheParams = window.BOLD.common.cacheParams || {};
                window.BOLD.common.cacheParams.recurring_orders = window.BOLD.common.cacheParams.recurring_orders || '1';

                BoldHelpers.load();
                new RecurringOrdersBootstrap(); //eslint-disable-line
            }
        })
        .catch((error) => {
            console.error('Error retrieving BS1 checkout processing status:', error);
        });
}
