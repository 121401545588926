const oneTimePurchase = `
<div class="bold-ro__radio-div bold-ro__one-time-div {{^subscription_checked}}bold-ro__bold-active{{/subscription_checked}}">
    <label class="bold-ro__recurring-lbl">
        <input type="radio" value="0" name="bold-ro__selector_radio_button"
            class="bold-ro__one-time-radio-btn"
            {{^subscription_checked}}checked=""{{/subscription_checked}}>
        <span class="bold-ro__one-time-purchase">
        {{#conversion}}
            {{#merge_one_time_price}}{{#translate}}one_time_purchase_convertible{{/translate}}{{/merge_one_time_price}}
        {{/conversion}}
        {{#standard}}
            {{#merge_one_time_price}}{{#translate}}one_time_purchase{{/translate}}{{/merge_one_time_price}}
        {{/standard}}
        </span>
    </label>
</div>
`;

export default oneTimePurchase;
