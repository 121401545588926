const cartLineItems = `
<div class="bold_recurring_desc" data-widget-2=true>
  {{#isConvertible}}
    <p>{{{convertibleNameLineItem}}}</p>
    <p>{{{convertibleDeliverEveryLineItem}}}</p>
  {{/isConvertible}}
  {{^isConvertible}}
    <p>{{{deliverEveryLineItem}}}</p>
    <p>{{{secondaryDiscountLineItem}}}</p>
  {{/isConvertible}}
  {{#totalRecurrencesLineItem}}
    <p>{{{totalRecurrencesLineItem}}}</p>
  {{/totalRecurrencesLineItem}}
 </div>
`;

export default cartLineItems;
