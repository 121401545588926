const fixedFrequencyInterval = `
{{#frequency_type}}
    {{#frequency_num}}
        <input type="hidden" name="frequency_num" class="bold-ro__frequency-num" value="{{frequency_num_id}}">
        <input type="hidden" name="frequency_type" class="bold-ro__frequency-type" value="{{frequency_type_id}}">
        <input name="frequency_type_text" class="bold-ro__frequency-type-text" type="hidden" value="{{#translate}}{{frequency_type_translation}}{{/translate}}">
    {{/frequency_num}}
{{/frequency_type}}
`;

export default fixedFrequencyInterval;
