import {
    SECONDARY_DISCOUNT_NUM_KEY,
    SECONDARY_DISCOUNT_REQUIED_ORDERS_KEY,
} from '../constants/properties';

const secondaryDiscount = `
    <input type="hidden" class="bold-ro__${SECONDARY_DISCOUNT_NUM_KEY}" value="{{secondary_discount.discount}}"/>
    <input type="hidden" class="bold-ro__${SECONDARY_DISCOUNT_REQUIED_ORDERS_KEY}" value={{secondary_discount.num_required_orders}} />
`;

export default secondaryDiscount;
