import Mustache from 'mustache';

import standard from './templates/standard';
import subscriptionBox from './templates/subscription_box';
import oneTimePurchase from './templates/one_time_purchase';
import convertibleTable from './templates/convertible';
import recurringCart from './templates/recurringCart';
import selectableFrequencyInterval from './templates/selectable_frequency_interval';
import billingPlan from './templates/billing_plan';
import fixedFrequencyInterval from './templates/fixed_frequency_interval';
import limitedLength from './templates/limited_length';
import recurringOrdersError from './templates/recurringOrdersError';
import customFrequencyInterval from './templates/customFrequencyInterval';
import standardFrequencyInterval from './templates/standard_interval';
import prepaid from './templates/prepaid';
import addToExisting from './templates/add_to_existing';
import shippingMethods from './templates/shipping_methods';
import secondaryDiscount from './templates/secondary_discount';
import widgetLoading from './templates/widget_loading';
import cartLineItems from './templates/cartlineitems';

export function setTemplate(type, template, overwrite = true) {
    try {
        Mustache.parse(template);

        if (overwrite || typeof window.BOLD.recurring_orders.templates[type] === 'undefined') {
            window.BOLD.recurring_orders.templates[type] = template;
            if (typeof window.BOLD.recurring_orders.app !== 'undefined') {
                window.BOLD.recurring_orders.app.refreshTemplates(type);
            }
        }
    } catch (e) {
        console.error(e);
        return e;
    }
    return null;
}

export function loadTemplates() {
    window.BOLD.recurring_orders.setTemplate = setTemplate;

    if (typeof window.BOLD.recurring_orders.templates === 'undefined') {
        window.BOLD.recurring_orders.templates = {};
    }

    const templates = {
        standard,
        subscriptionBox,
        oneTimePurchase,
        convertibleTable,
        recurringCart,
        selectableFrequencyInterval,
        standardFrequencyInterval,
        billingPlan,
        fixedFrequencyInterval,
        limitedLength,
        recurringOrdersError,
        customFrequencyInterval,
        prepaid,
        addToExisting,
        shippingMethods,
        secondaryDiscount,
        widgetLoading,
        cartLineItems,
    };

    Object.keys(templates).forEach((key) => {
        setTemplate(key, templates[key].trim(), false);
    });
}
