export function isCashierAlwaysOn() {
    return window.BOLD
      && window.BOLD.checkout_features_defaults
      && window.BOLD.checkout_features_defaults.find((feature) => feature.name === 'checkout-always-on').require;
}

export function isCashierEnabled() {
    return window.BOLD && window.BOLD.checkout;
}

export function changeCashierFeatureToken(value) {
    if (window.BOLD.checkout_features_defaults && window.BOLD.recurring_orders.application_uuid) {
        window.BOLD.checkout_features_defaults.forEach((item) => {
            if (item.id === window.BOLD.recurring_orders.application_uuid) {
                item.require = value;
            }
        });
    }
}

export function waitForCheckoutObject() {
    return new Promise((resolve) => {
        if (window.BOLD && window.BOLD.checkout) {
            resolve(window.BOLD.checkout);
        } else {
            const checkInterval = setInterval(() => {
                if (window.BOLD && window.BOLD.checkout) {
                    clearInterval(checkInterval);
                    resolve(window.BOLD.checkout);
                }
            }, 100);
        }
    });
}
