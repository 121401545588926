import Mustache from 'mustache';
import map from 'lodash/map';
import { DOMHelper } from '@bold-commerce/bold-common-js';

class DOMHandler {
    constructor(type, fields, parent, append = true) {
        this.templateType = type;
        this.parent = parent;
        this.fields = fields;
        this.element = this.renderTemplate();
        this.errorElement = null;
        this.visible = true;
        this.errorClass = 'recurring_orders_error';

        if (append) {
            this.append();
        }
    }

    getTemplate() {
        return window.BOLD.recurring_orders.templates[this.templateType];
    }

    getErrorTemplate() {
        return window.BOLD.recurring_orders.templates.recurringOrdersError;
    }

    renderTemplate() {
        return DOMHelper.getWrappingElement(Mustache.render(this.getTemplate(), this.fields, window.BOLD.recurring_orders.templates));
    }

    refreshTemplate() {
        const element = this.renderTemplate();
        this.parent.replaceChild(element, this.element);
        this.element = element;

        if (!this.visible) {
            this.hide();
        }
    }

    append() {
        this.parent.appendChild(this.element);
    }

    prepend() {
        this.parent.insertBefore(this.element, this.parent.firstChild);
    }

    show() {
        map(this.childNodes, (c) => this.element.appendChild(c));
        this.visible = true;
    }

    hide() {
        this.childNodes = [];
        while (this.element.firstChild) {
            this.childNodes.push(this.element.removeChild(this.element.firstChild));
        }
        this.visible = false;
    }

    addErrorClass() {
        DOMHelper.addClass(this.element, this.errorClass);
    }

    removeErrorClass() {
        DOMHelper.removeClass(this.element, this.errorClass);
    }

    showError(message) {
        this.removeError();

        if (message !== '') {
            const template = this.getErrorTemplate();
            const fields = { error: message };
            Mustache.parse(template);
            const renderedTemplate = Mustache.render(template, fields);
            this.errorElement = DOMHelper.getWrappingElement(renderedTemplate);

            this.element.appendChild(this.errorElement);
        }

        this.addErrorClass();
    }

    setFields(fields) {
        this.fields = fields;
    }

    removeError() {
        if (this.errorElement !== null) {
            this.element.removeChild(this.errorElement);
            this.errorElement = null;
        }
        this.removeErrorClass();
    }
}

export default DOMHandler;
